/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../auth/redux/AuthRedux'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import {
  signInWithGoogle,
  signInWithEmailAndPassword,
} from "./firebase";

const loginSchema = Yup.object().shape({
  email: Yup.string()
      .email('Email không đúng định dạng')
      .min(3, 'Email phải có ít nhất 3 kí tự')
      .max(50, 'Email chỉ có nhiều nhất 50 kí tự')
      .required('Vui lòng nhập Email'),
  password: Yup.string()
      .min(3, 'Mật khẩu phải có ít nhất 3 kí tự')
      .max(50, 'Mật khẩu chỉ có nhiều nhất 50 kí tự')
      .required('Vui lòng nhập mật khẩu'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function RentMailLogin() {

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      signInWithEmailAndPassword(values.email, values.password, (user: {uid: string, email: string}, idToken: string) => {
        setLoading(false);
        dispatch(auth.actions.loginByEmailPassword(user, idToken));
      }, (errorMsg: string) => {
        setLoading(false);
        setErrorMsg(errorMsg);
      });
    },
  });

  const signInByGoogle = () => {
    signInWithGoogle((accessToken: string, idToken: string) => {
        dispatch(auth.actions.loginByGoogle(accessToken, idToken));
    });
  }

  return (
      <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Đăng nhập</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            Chưa có tài khoản?{' '}
            <Link to='/auth/registration' className='link-primary fw-bolder'>
              Tạo tài khoản
            </Link>
          </div>
        </div>
        {/* begin::Heading */}

        {errorMsg ? <div className='mb-10 bg-light-warning p-8 rounded'>
          <div className='text-danger'>
            <strong>{errorMsg}</strong>
          </div>
        </div> : null}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
              )}
              type='email'
              name='email'
              autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mật khẩu</label>
              {/* end::Label */}
              {/* begin::Link */}
              {/* <Link
                  to='/auth/forgot-password'
                  className='link-primary fs-6 fw-bolder'
                  style={{marginLeft: '5px'}}
              >
                Quên mật khẩu ?
              </Link> */}
              {/* end::Link */}
            </div>
          </div>
          <input
              type='password'
              placeholder='Mật khẩu'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
              )}
          />
          {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={loading || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Đăng nhập</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Vui lòng chờ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
            )}
          </button>

          {/* begin::Separator */}
          <div className='text-center text-muted text-uppercase fw-bolder mb-5'>hoặc</div>
          {/* end::Separator */}

          {/* begin::Google link */}
          <button onClick={signInByGoogle} type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
            <img
              alt='Đăng nhập bằng Google'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-20px me-3'
            />
            Đăng nhập bằng Google
          </button>
          {/* end::Google link */}

          {/* begin::Google link */}
          {/* <div className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
            <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                className='h-20px me-3'
            />
            Đăng nhập bằng Facebook
          </div> */}
          {/* end::Google link */}

          {/* begin::Google link */}
          {/* <div className='btn btn-flex flex-center btn-light btn-lg w-100'>
            <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
                className='h-20px me-3'
            />
            Đăng nhập bằng Apple
          </div> */}
          {/* end::Google link */}
        </div>
        {/* end::Action */}
      </form>
  )
}
