/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import * as rentMail from './redux/RentMailRedux'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { UserRentMailModel } from './models/UserRentMailModel'
import { Loading } from './components/Loading'
import { CommonFunc } from './common/common'
import { actionTypes } from './redux/RentMailRedux'
import _ from 'lodash'
import { TransactionModel } from './models/TransactionModel'
import { SellMailTable } from './components/SellMailTable'
import { ServiceModel } from './models/ServiceModel'

const NOT_ENOUGH_COIN_MSG = 'Số coin trong tài khoản của bạn không đủ.';

const SellMailWrapper: FC = () => {
  const services: ServiceModel[] =
    useSelector<RootState>(({rentMail}) => _.get(rentMail, 'services', []), shallowEqual) as ServiceModel[];
  const isBuyMail: boolean =
    useSelector<RootState>(({rentMail}) => _.get(rentMail, `isLoading_${actionTypes.CreateRentMail}`, false), shallowEqual) as boolean;
  const percentageBuyMail: number =
    useSelector<RootState>(({rentMail}) => _.get(rentMail, 'percentageSellMail', 0), shallowEqual) as number;
  const userRentMail: UserRentMailModel =
    useSelector<RootState>(({auth}) => auth.userRentMail, shallowEqual) as UserRentMailModel;
  const transaction: {lstTrans: TransactionModel[], total: number} =
    useSelector<RootState>(({rentMail}) => rentMail.transaction, shallowEqual) as {lstTrans: TransactionModel[], total: number};
  const dispatch = useDispatch();
  const [warningMsg, setWarnMsg] = useState('');
  const createRentMail = (emailType: string, numEmail: number, requestMailType: string) => {
    const service = _.find(services, s => s.key === emailType);
    if (userRentMail.balance < _.get(service, 'price', 0)*numEmail) {
      setWarnMsg(NOT_ENOUGH_COIN_MSG);
    } else {
      dispatch(rentMail.actionsRentMails.createSellMail(service?.type as string, emailType, numEmail, requestMailType));
    }
  }

  useEffect(() => {
    dispatch(rentMail.actionsRentMails.getServices(['mail', 'textnow']));
  }, []);

  const refreshWarningMsg = () => {
    setWarnMsg('');
  }

  const isLoading: boolean =
    useSelector<RootState>(({rentMail}) => CommonFunc.isLoading(rentMail, actionTypes), shallowEqual) as boolean;
  return (
    <>
      {
        isBuyMail ? <Loading
          isLoading={isLoading}
          type={"getPercentage"}
          percentage={percentageBuyMail}
        /> : <Loading
          isLoading={isLoading}
        />
      }
      <PageTitle breadcrumbs={[]}>Mail - Clone FB</PageTitle>
      <div className='row g-5 gx-xxl-8 spinner spinner-primary mr-15'>
        <div>
          <SellMailTable
            services={services}
            transactions={_.get(transaction, 'lstTrans', []).slice(0, 10)}
            totalRows={_.get(transaction, 'total', 0)}
            onHandleBuyEmail={createRentMail}
            warningMsg={warningMsg}
            refreshWarningMsg={refreshWarningMsg}
            className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div>
    </>
  )
}

export default SellMailWrapper
