/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { DefaultTitle } from '../../../../_metronic/layout/components/header/page-title/DefaultTitle'
import { useLayout } from '../../../../_metronic/layout/core'

const RentMailToolbar: FC = () => {
  const {classes} = useLayout()
  return (
    <div>
      <div>
        {/* begin::Container */}
        {/* <div
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack fw-bold fs-6')}
          style={{ marginTop: '10px', color: 'yellow' }}
        >
          {ReactHtmlParser(_.get(message, 'notify', ''))}
        </div> */}
        {/* end::Container */}
      </div>
      <div className='toolbar py-5 py-lg-15' id='kt_toolbar'>
        {/* begin::Container */}
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />
        </div>
        {/* end::Container */}
      </div>
    </div>
  )
}

export {RentMailToolbar}
