import { Input, Select } from "antd";
import _ from "lodash";
import React, { useState } from "react";

type Props = {
  numRows: number,
  currentPage: number,
  numRowsPerPage?: number,
  canNumRowChange?: boolean,
  callbackPageChange: Function,
  callbackNumRowChange?: (value: string) => void,
}

type Page = {
  pageNumber: number
}

const MAX_PAGE_SHOW = 5;

const RentMailPagination: React.FC<Props> = ({
  numRows,
  numRowsPerPage = 10,
  currentPage,
  canNumRowChange = false,
  callbackPageChange,
  callbackNumRowChange}) => {

  const [pageInput, setPageInput] = useState(0);

  const numPages = Math.ceil(numRows/numRowsPerPage);
  let pageObjArray: Page[] = [];
  let pageNumStart = 1;
  let pageNumEnd = numPages < MAX_PAGE_SHOW ? (numPages+1) : (1+MAX_PAGE_SHOW);
  if (currentPage - 2 > 0) {
    pageNumStart = (numPages < currentPage + 2 ) ? (((numPages - MAX_PAGE_SHOW) < 0 ? 0 : (numPages - MAX_PAGE_SHOW)) + 1) : currentPage - 2;
    pageNumEnd = (pageNumStart + MAX_PAGE_SHOW) <= numPages ? (pageNumStart + MAX_PAGE_SHOW) : (numPages+1);
  }
  for (let i = pageNumStart; i < pageNumEnd; i++) {
    pageObjArray.push({pageNumber: i});
  }
  const onPageChange = (pageNum: number) => {
    callbackPageChange(pageNum);
  }
  return (
    <div className='d-flex flex-stack flex-wrap pt-10'>
      <div className='fs-6 fw-bold text-gray-700'>
        Showing {(currentPage-1)*numRowsPerPage+1} to {numRows < currentPage*numRowsPerPage ? numRows: currentPage*numRowsPerPage} of {numRows} entries
      </div>
      <ul className='pagination'>
        <li className={`page-item previous ${currentPage === 1 ? 'disabled' : null}`}>
          <a onClick={() => onPageChange(1)} className='page-link card-hover-pointer'>
            <i className='previous'></i>
            <i className='previous'></i>
          </a>
        </li>
        <li className={`page-item previous ${currentPage === 1 ? 'disabled' : null}`}>
          <a onClick={() => onPageChange(currentPage === 1 ? 1 : (currentPage-1))} className='page-link card-hover-pointer'>
            <i className='previous'></i>
          </a>
        </li>
        {
          pageObjArray.map((p, idx) => (<li key={idx} className={`page-item ${p.pageNumber === currentPage? "active": null}`}>
          {p.pageNumber === currentPage?
            <a className="page-link">
              {p.pageNumber}
            </a> :
            <a onClick={() => onPageChange(p.pageNumber)} className="page-link card-hover-pointer">
              {p.pageNumber}
            </a>}
        </li>))
        }
        <li className={`page-item next ${currentPage === numPages ? 'disabled' : null}`}>
          <a onClick={() => onPageChange(currentPage === numPages ? numPages : (currentPage+1))} className='page-link card-hover-pointer'>
            <i className='next'></i>
          </a>
        </li>
        <li className={`page-item next ${currentPage === numPages ? 'disabled' : null}`}>
          <a onClick={() => onPageChange(numPages)} className='page-link card-hover-pointer'>
            <i className='next'></i>
            <i className='next'></i>
          </a>
        </li>
        <li>
          <Input
            style={{ width: '80px' }}
            type='number'
            placeholder='page'
            onChange={(e) => {
              const page = +e.target.value;
              setPageInput(numPages < page ? numPages : page);
            }}
            onPressEnter={(e) => {
              if (e.key === 'Enter') {
                onPageChange(pageInput);
              }
            }}></Input>
        </li>
        {canNumRowChange ? <li>
          <Select
            defaultValue={_.toString(numRowsPerPage)}
            style={{ width: 70, marginLeft: '5px' }}
            onChange={callbackNumRowChange}
            options={[
              { value: '5', label: '5' },
              { value: '10', label: '10' },
              { value: '15', label: '15' },
              { value: '20', label: '20' },
            ]}
          />
        </li> : null}
      </ul>
    </div>
  );
}

export default RentMailPagination;