import React from 'react'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import { UserRentMailModel } from '../../../../app/modules/rent-mails/models/UserRentMailModel';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import _ from 'lodash';
import { MenuInnerWithSub } from './MenuInnerWithSub';

export function RentMailMenuInner() {
  const intl = useIntl();
  const userRentMail: UserRentMailModel =
    useSelector<RootState>(({auth}) => auth.userRentMail, shallowEqual) as UserRentMailModel;
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/main' />
      <MenuItem title='Mail - Clone FB' to='/mail' />
      <MenuInnerWithSub
        title='Lịch sử'
        to='/history'
        hasArrow={true}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem title='TextNow' to='/historyPhone' hasBullet={true} />
        <MenuItem title='Mail' to='/historyMail' hasBullet={true} />
        <MenuItem title='Acc Textnow' to='/historyTx' hasBullet={true} />
        <MenuItem title='Facebook' to='/historyFacebook' hasBullet={true} />
      </MenuInnerWithSub>
      <MenuItem title='Nạp tiền' to='/payment' />
      <MenuInnerWithSub
        title='Biến động số dư'
        to='/transaction'
        hasArrow={true}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem title='TextNow' to='/transactionPhone' hasBullet={true} />
        <MenuItem title='Mail' to='/transactionMail' hasBullet={true}  />
        <MenuItem title='Acc Textnow' to='/transactionTx' hasBullet={true}  />
        <MenuItem title='Facebook' to='/transactionFacebook' hasBullet={true}  />
      </MenuInnerWithSub>
      <MenuItem title='Hướng dẫn tích hợp API' to='/apiGuide' />
      {/* <MenuInnerWithSub
        title='Tiện ích'
        to='/'
        hasArrow={true}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem title='Check Live UID' to='/checkLiveUid' hasBullet={true} />
        <MenuItem title='Check Mail' to='/checkMail' hasBullet={true}  />
        <MenuItem title='Get Code Mail' to='/getCodeMail' hasBullet={true}  />
        <MenuItem title='Đọc hòm thư' to='/readMailInbox' hasBullet={true}  />
        <MenuItem title='Get 2FA' to='/getTwoFa' hasBullet={true}  />
      </MenuInnerWithSub> */}
      {_.get(userRentMail, 'isAdmin', false) ? <MenuItem title='Admin' to='/admin' /> : null}
    </>
  )
}
