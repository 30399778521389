/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { RentMailLogin } from '../rent-mails/RentMailLogin'
import { RentMailRegistration } from '../rent-mails/RentMailRegistration'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
      <div
          className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
          // style={{
          //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
          // }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          {/* <a href='#' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
          </a> */}
          <div className='mb-12'>
            <img alt='WinMail' src={toAbsoluteUrl('/media/logos/logo_198px_white.png')} className='h-60px' />
            {/* <h1 className='text-white fw-bolder'>WinMail</h1> */}
          </div>
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}
        {/* <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <a href='#' className='text-muted text-hover-primary px-2'>
              About
            </a>

            <a href='#' className='text-muted text-hover-primary px-2'>
              Contact
            </a>

            <a href='#' className='text-muted text-hover-primary px-2'>
              Contact Us
            </a>
          </div>
        </div> */}
        {/* end::Footer */}
      </div>
  )
}

const AuthPage = () => (
    <Routes>
      <Route element={<AuthLayout />}>
        {/* <Route path='login' element={<Login />} /> */}
        <Route path='registration' element={<RentMailRegistration />} />
        {/* <Route path='forgot-password' element={<ForgotPassword />} /> */}
        <Route index element={<RentMailLogin />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
)

export {AuthPage}
