import clsx from 'clsx'
import _ from 'lodash'
import {FC} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import {KTSVG} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search} from '../../../partials'
import { RentMailHeaderUserMenu } from '../../../partials/layout/header-menus/RentMailHeaderUserMenu'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* Quick links */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper */}
          {/* <a
              className={clsx(
                  'btn btn-icon btn-active-light-primary btn-custom',
                  toolbarButtonHeightClass
              )}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              href='http://m.me/winmail38'
              target="_blank"
          >
            <KTSVG
                path='/media/icons/duotune/social/facebook-messenger.svg'
                className={toolbarButtonIconSizeClass}
            />
          </a> */}
          {/* <QuickLinks /> */}
          {/* end::Menu wrapper */}
        </div>

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-30px w-30px rounded'
              src={_.get(user, 'picture', 'https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/User.svg')}
              alt='metronic'
            />
          </div>
          <RentMailHeaderUserMenu/>
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export {Topbar}
