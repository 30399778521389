import { Modal } from "antd";
import _ from "lodash";
import moment from "moment";
import { DataChartType } from "../redux/RentMailCRUD";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const CommonFunc = {
  isLoading: (state: any, actions: any) => {
    return !!Object.keys(actions).find(key => state[`isLoading_${actions[key]}`] === true);
  }
}

export const createDataChartByYear = (dataChart: DataChartType[]) : {
  year: string;
  total: number;
}[] => {
  if (dataChart.length === 0) {
    return[];
  }
  const dataChartByYear = _.groupBy(dataChart, d => d._id.year);
  const dataChartByYearArr: {year: string, total: number}[] = [];
  Object.keys(dataChartByYear)
    .forEach(year => dataChartByYearArr.push({year, total: _.sumBy(dataChartByYear[year], y => y.total)}));
  const minDataChartYear = _.minBy(dataChartByYearArr, d => _.toNumber(d.year));
  const maxDataChartYear = _.maxBy(dataChartByYearArr, d => _.toNumber(d.year));
  const dataChartByYearArrDisp: {year: string, total: number}[] = [];
  let startYearNum: number = _.toNumber(minDataChartYear?.year);
  const endYearNum = _.toNumber(maxDataChartYear?.year);
  while(startYearNum <= endYearNum) {
    const existData = _.find(dataChartByYearArr, d => d.year === _.toString(startYearNum));
    if (existData) {
      dataChartByYearArrDisp.push(existData);
    } else {
      dataChartByYearArrDisp.push({year: _.toString(startYearNum), total: 0});
    }
    startYearNum += 1;
  }
  return dataChartByYearArrDisp;
}

export const createDataChartByMonth = (dataChart: DataChartType[]) : {
  month: string;
  total: number;
}[] => {
  if (dataChart.length === 0) {
    return[];
  }
  const dataChartByMonth = _.groupBy(dataChart, d => `${d._id.year}/${_.padStart(_.toString(d._id.month), 2, '0')}`);
  // dataChartByMonth: [{2022/03: [{_id: {year: 2022, month: 3, week: 3}, total: 900}, {...}, ...]},...]
  const dataChartByMonthArr: {month: string, total: number}[] = [];
  Object.keys(dataChartByMonth)
    .forEach(key => dataChartByMonthArr.push({month: key, total: _.sumBy(dataChartByMonth[key], d => d.total)}));
  // dataChartByMonthArr: [{month: '2022/03', total: 9000}, {...}, ...]
  const minMonth = _.minBy(dataChartByMonthArr, d => d.month);
  const maxMonth = _.maxBy(dataChartByMonthArr, d => d.month);
  // @ts-ignore
  const minMonthDateType = new Date(minMonth?.month);
  // @ts-ignore
  const maxMonthDateType = new Date(maxMonth?.month);
  const dataChartByMonthArrDisp: {month: string, total: number}[] = [];
  let startMonth = minMonthDateType;
  while (startMonth <= maxMonthDateType) {
    // @ts-ignore
    const startMonthYYYYMM: {month: string, total: number} =
      _.find(dataChartByMonthArr, d => d.month === moment(startMonth).format("YYYY/MM"));
    if (startMonthYYYYMM) {
      dataChartByMonthArrDisp.push({month: startMonthYYYYMM.month, total: startMonthYYYYMM.total});
    } else {
      // @ts-ignore
      dataChartByMonthArrDisp.push({month: moment(startMonth).format("YYYY/MM"), total: 0});
    }
    startMonth = moment(startMonth).add(1, 'M').toDate();
  }
  return dataChartByMonthArrDisp;
}

export const createDataChartByWeek = (dataChart: DataChartType[]) : {
  lastWeek: string;
  total: number;
}[] =>{
  if (dataChart.length === 0) {
    return[];
  }
  const dataChartByLastWeek = _.groupBy(dataChart, d => moment(`${d._id.year}`).add(d._id.week, 'weeks').format('YYYY/MM/DD'));
  // dataChartByLastWeek: [{2022/03: [{_id: {year: 2022, month: 3, week: 3}, total: 900}, {...}, ...]},...]
  const dataChartByLastWeekArr: {lastWeek: string, total: number}[] = [];
  Object.keys(dataChartByLastWeek)
    .forEach(key => dataChartByLastWeekArr.push({lastWeek: key, total: _.sumBy(dataChartByLastWeek[key], d => d.total)}));
  // dataChartByLastWeekArr: [{lastWeek: '2022/03/12', total: 9000}, {...}, ...]
  const minLastWeek = _.minBy(dataChartByLastWeekArr, d => d.lastWeek);
  const maxLastWeek = _.maxBy(dataChartByLastWeekArr, d => d.lastWeek);
  // @ts-ignore
  const minLastWeekDateType = new Date(minLastWeek?.lastWeek);
  // @ts-ignore
  const maxLastWeekDateType = new Date(maxLastWeek?.lastWeek);
  const dataChartByLastWeekArrDisp: {lastWeek: string, total: number}[] = [];
  let startLastWeek = minLastWeekDateType;
  while (startLastWeek <= maxLastWeekDateType) {
    // @ts-ignore
    const startLastWeekYYYYMMDD: {lastWeek: string, total: number} =
      _.find(dataChartByLastWeekArr, d => d.lastWeek === moment(startLastWeek).format("YYYY/MM/DD"));
    if (startLastWeekYYYYMMDD) {
      dataChartByLastWeekArrDisp.push({lastWeek: startLastWeekYYYYMMDD.lastWeek, total: startLastWeekYYYYMMDD.total});
    } else {
      // @ts-ignore
      dataChartByLastWeekArrDisp.push({lastWeek: moment(startLastWeek).format("YYYY/MM/DD"), total: 0});
    }
    startLastWeek = moment(startLastWeek).add(1, 'weeks').toDate();
  }
  return dataChartByLastWeekArrDisp;
}

export const createDataChartByDay = (dataChart: DataChartType[]) =>{
  if (dataChart.length === 0) {
    return [];
  }
  const dataChartByDate = _.groupBy(dataChart, d => `${d._id.year}/${_.padStart(_.toString(d._id.month), 2, '0')}/${_.padStart(_.toString(d._id.day), 2, '0')}`);
  const dataChartByDateArr: {date: string, total: number}[] = [];
  Object.keys(dataChartByDate).forEach(key => {
    dataChartByDateArr.push({date: key, total: _.sumBy(dataChartByDate[key], d => d.total)});
  });
  const minDate = _.minBy(dataChartByDateArr, d => d.date);
  const maxDate = _.maxBy(dataChartByDateArr, d => d.date);
  // @ts-ignore
  const minDateType = new Date(minDate?.date);
  // @ts-ignore
  const maxDateType = new Date(maxDate?.date);
  let startDate = minDateType;
  const dataChartByDateArrDisp : {date: string, total: number}[] = [];
  while (startDate <= maxDateType) {
    const startDateYYYYMMDD = _.find(dataChartByDateArr, d => moment(startDate).format('YYYY/MM/DD') === d.date);
    if (startDateYYYYMMDD) {
      dataChartByDateArrDisp.push({date: startDateYYYYMMDD.date, total: startDateYYYYMMDD.total});
    } else {
      dataChartByDateArrDisp.push({date: moment(startDate).format('YYYY/MM/DD'), total: 0});
    }
    startDate = moment(startDate).add(1, 'days').toDate();
  }
  return dataChartByDateArrDisp;
}

export const toIsoString = (date: any) => {
  const tzo = -date.getTimezoneOffset(),
  dif = tzo >= 0 ? '+' : '-',
  pad = function(num: number) {
    return (num < 10 ? '0' : '') + num;
  };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' + pad(Math.abs(tzo) % 60);
}

export const showModalConfirm = (
  title: string,
  content: string,
  onOk = () => console.log('onOk'),
  onCancel = () => console.log('onCancel')
) => {
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText: 'OK',
    cancelText: 'Cancel',
    onOk,
    onCancel,
    centered: true,
  });
};