import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import RentMailApiGuide from '../modules/rent-mails/RentMailApiGuide'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import _ from 'lodash'
import SellMailInit from '../modules/rent-mails/redux/SellMailInit'
import SellMailWrapper from '../modules/rent-mails/SellMailWrapper'
import FacebookHistoryWrapper from '../modules/rent-mails/FacebookHistoryWrapper'
import FacebookTransactionWrapper from '../modules/rent-mails/FacebookTransactionWrapper'
import TxHistoryWrapper from '../modules/rent-mails/TxHistoryWrapper'
import TxTransactionWrapper from '../modules/rent-mails/TxTransactionWrapper'
    
const PrivateRoutes = () => {
    const RentMailMainInit = lazy(() => import('../modules/rent-mails/redux/RentMailMainInit'))
    const RentMailWrapper = lazy(() => import('../modules/rent-mails/RentMailWrapper'))
    const HistoryWrapper = lazy(() => import('../modules/rent-mails/HistoryWrapper'))
    const MailHistoryWrapper = lazy(() => import('../modules/rent-mails/MailHistoryWrapper'))
    const TransactionWrapper = lazy(() => import('../modules/rent-mails/TransactionWrapper'))
    const MailTransactionWrapper = lazy(() => import('../modules/rent-mails/MailTransactionWrapper'))
    const RentMailPayment = lazy(() => import('../modules/rent-mails/RentMailPayment'))
    const AdminWrapper = lazy(() => import('../modules/rent-mails/AdminWrapper'))
    const isAdmin = useSelector<RootState>(({auth}) => _.get(auth, 'userRentMail.isAdmin'), shallowEqual);
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/main' />} />
                {/* Pages */}
                <Route path='main' element={
                    <RentMailMainInit>
                        <RentMailWrapper />
                    </RentMailMainInit>
                } />
                <Route path='mail' element={
                    <SellMailInit>
                        <SellMailWrapper />
                    </SellMailInit>
                } />
                <Route path='historyPhone' element={<HistoryWrapper />} />
                <Route path='historyMail' element={<MailHistoryWrapper />} />
                <Route path='historyTx' element={<TxHistoryWrapper />} />
                <Route path='historyFacebook' element={<FacebookHistoryWrapper />} />
                <Route path='transactionPhone' element={<TransactionWrapper />} />
                <Route path='transactionMail' element={<MailTransactionWrapper />} />
                <Route path='transactionTx' element={<TxTransactionWrapper />} />
                <Route path='transactionFacebook' element={<FacebookTransactionWrapper />} />
                <Route path='payment' element={<RentMailPayment className='card-xxl-stretch mb-5 mb-xxl-8' />} />
                <Route path='apiGuide' element={<RentMailApiGuide className='card-xxl-stretch mb-5 mb-xxl-8' />} />
                {isAdmin ? <Route path='admin' element={<AdminWrapper />} /> : null}
                {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
                {/* Lazy Modules */}
                {/* <Route
                    path='crafted/pages/profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/pages/wizards/*'
                    element={
                        <SuspensedView>
                            <WizardsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/widgets/*'
                    element={
                        <SuspensedView>
                            <WidgetsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/account/*'
                    element={
                        <SuspensedView>
                            <AccountPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='apps/chat/*'
                    element={
                        <SuspensedView>
                            <ChatPage />
                        </SuspensedView>
                    }
                /> */}
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC = ({children}) => {
    TopBarProgress.config({
        barThickness: 3,
        shadowBlur: 5,
    });
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
