import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL
const RENT_MAIL_API_URL = process.env.REACT_APP_RENT_MAIL_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const GET_USER_BY_GOOGLE_ACCESSTOKEN_URL = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=`
export const GET_USER_BY_ID = `${RENT_MAIL_API_URL}/users/profile`;
export const GET_USER_BY_LOGIN = `${RENT_MAIL_API_URL}/users/login`;
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token:token
  })
}

export function getUserByGoogleToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_GOOGLE_ACCESSTOKEN_URL + token)
}

export function getUserProfile(userName: string, accountType: string) {
  return axios.get<{code: number, user: UserModel}>(GET_USER_BY_ID, {params: {userName, accountType}});
}

export function getUserByLogin() {
  return axios.get<UserModel>(GET_USER_BY_LOGIN)
}