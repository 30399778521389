import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {
  DrawerMessenger,
  RightToolbar,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { RentMailToolbar } from '../../app/modules/rent-mails/components/RentMailToolbar'
import { RentMailFooter } from '../../app/modules/rent-mails/components/RentMailFooter'
import * as rentMailRedux from '../../app/modules/rent-mails/redux/RentMailRedux'
import { useDispatch } from 'react-redux'

const MasterLayout: React.FC = ({children}) => {
  const {classes} = useLayout()

  const location = useLocation()

  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      dispatch(rentMailRedux.actionsRentMails.getConfig())
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            {/* <Toolbar /> */}
            <RentMailToolbar />
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
              style={{ maxWidth: '2000px' }}
            >
              <AsideDefault />
                <Content>
                  <Outlet />
                </Content>
            </div>
          </div>
          {/* <Footer /> */}
          <RentMailFooter />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar />
      <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <Main />
      <InviteUsers />
      <UpgradePlan /> */}
      {/* end:: Modals */}
      {/* <ScrollTop /> */}
    </PageDataProvider>
  )
}

export {MasterLayout}
