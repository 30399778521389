/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RootState } from '../../../setup';
import { KTSVG } from '../../../_metronic/helpers';
import { UserRentMailModel } from './models/UserRentMailModel';
import _ from 'lodash';
import { PageTitle } from '../../../_metronic/layout/core';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
// @ts-ignore
import GoogleDocsViewer from 'react-google-docs-viewer';

type Props = {
  className: string
}

const RentMailApiGuide: React.FC<Props> = ({className}) => {
  const userRentMail: UserRentMailModel =
    useSelector<RootState>(({auth}) => auth.userRentMail, shallowEqual) as UserRentMailModel;
  const [copiedId, setCopiedId] = useState(false);
  // @ts-ignore
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      width: '100%',
    },
  }));
  const classes = useStyles();
  return (
    <div>
      {/* begin::Beader */}
      <PageTitle breadcrumbs={[]}>Hướng dẫn tích hợp API</PageTitle>
      <div className='card-header border-0 py-5 min-height-500px'>
        <div className="width-fix-content">
          {/* <SwaggerUI url="https://petstore.swagger.io/v2/swagger.json" /> */}
          <OverlayTrigger
            key='copy-to-clipboard'
            placement='top'
            overlay={<Tooltip id='tooltip-copy-to-clipboard'>{copiedId ? 'copied' : 'Click to copy'}</Tooltip>}
          >
            <CopyToClipboard text={userRentMail.key} onCopy={() => setCopiedId(true)}>
              {/* <a className='highlight-copy card-hover-pointer'>
                <span className='text-primary fw-bolder d-block fs-6'>
                  {mail.email}
                </span>
              </a> */}
              <div className="text-align-left card-hover-pointer">
                <span className='text-danger fw-bolder'>clientId: </span><span className="text-primary fs-4">{userRentMail.key}</span>
              </div>
            </CopyToClipboard>
          </OverlayTrigger>
        </div>
        <hr></hr>
        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mt-5'>
            <KTSVG
              path='icons/duotune/general/gen044.svg'
              className='svg-icon-2tx svg-icon-warning me-4'
            />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Lưu ý!</h4>
                <div className='fs-6 text-gray-600'>
                  <span>- Sử dụng phương thức GET cho mọi request.</span><br/>
                  <span>- Dữ liệu trả về theo định dạng JSON.</span><br/>
                  <span>- Luôn đính kèm clientId cho mọi request.</span>
                </div>
              </div>
            </div>
        </div>
        <div className='notice bg-light-success rounded border-primary border border-dashed p-6 mt-5'>
          <div>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Hướng dẫn sử dụng API thuê TextNow:</h4>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="bg-light-primary"
                >
                  <Typography className={_.get(classes, 'heading')}>
                    <span className="api-doc-header-summary-button">GET</span>
                    <span className="api-doc-header-summary-title">{`/services/all?clientId=<clientId>&type=phone`}</span>
                    <span className="api-doc-header-summary-title">{`Lấy thông tin danh sách các service TextNow`}</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="width-100">
                    <h4>Parameters</h4><hr/>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bolder text-muted'>
                            <th className='width-100px'>Tên</th>
                            <th className='width-100px'>Kiểu</th>
                            <th className='width-100px'>Bắt buộc</th>
                            <th className='min-w-100px'>Mô tả</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>clientId</td>
                            <td>chuỗi</td>
                            <td>Có</td>
                            <td>Đây là id định danh cho mỗi User</td>
                          </tr>
                          <tr>
                            <td>type</td>
                            <td>chuỗi</td>
                            <td>Không</td>
                            <td>Loại service. Ví dụ: phone, mail...</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <span>Ví dụ: <code>https://api.winmail.shop/services/all?clientId={userRentMail.key}&type=phone</code></span>

                    <h4 className="mt-10">Response</h4><hr/>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bolder text-muted'>
                            <th className='width-100px'>Code</th>
                            <th className='min-w-100px'>Mô tả</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="vertical-align-top">200</td>
                            <td>
                              <span>Response trả về thành công.</span>
                              <div>
                                Ví dụ:
                                <pre>
                                  <code>
                                    {
`{
"code": 200,
"services": [
  {
    "name": "Facebook",
    "key": "facebook"
  },
  {
    "name": "Amazon",
    "key": "amazon"
  }
]
}`
                                    }
                                  </code>
                                </pre>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="vertical-align-top">401</td>
                            <td>
                              <span>Response trả về lỗi nếu thiếu param <code>clientId</code> trong request.</span>
                              <div>
                                Ví dụ:
                                <pre>
                                  <code>
                                    {
`{
"code": 401,
"error": "Expired!"
}`
                                    }
                                  </code>
                                </pre>
                              </div>
                            </td>
                          </tr>
                          <tr>
                              <td className="vertical-align-top">403</td>
                              <td>
                                <span>Response trả về lỗi nếu param <code>clientId</code> không đúng.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 403,
"error": "Forbidden!"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <div className="mt-5">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="bg-light-primary"
                  >
                    <Typography className={_.get(classes, 'heading')}>
                      <span className="api-doc-header-summary-button">GET</span>
                      <span className="api-doc-header-summary-title">{`/phone/rent?clientId=<clientKey>&serviceId=<serviceKey>`}</span>
                      <span className="api-doc-header-summary-title">{`Thực hiện thuê TextNow`}</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="width-100">
                      <h4>Parameters</h4><hr/>
                      <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                          <thead>
                            <tr className='fw-bolder text-muted'>
                              <th className='width-100px'>Tên</th>
                              <th className='width-100px'>Kiểu</th>
                              <th className='width-100px'>Bắt buộc</th>
                              <th className='min-w-100px'>Mô tả</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>clientId</td>
                              <td>chuỗi</td>
                              <td>Có</td>
                              <td>Đây là id định danh cho mỗi User</td>
                            </tr>
                            <tr>
                              <td>serviceId</td>
                              <td>chuỗi</td>
                              <td>Có</td>
                              <td>Đây là key định nghĩa cho mỗi dịch vụ (Ví dụ: facebook, twitter, ...)</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <span>Ví dụ: <code>https://api.winmail.shop/phone/rent?clientId={userRentMail.key}&serviceId=facebook</code></span>

                      <h4 className="mt-10">Response</h4><hr/>
                      <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                          <thead>
                            <tr className='fw-bolder text-muted'>
                              <th className='width-100px'>Code</th>
                              <th className='min-w-100px'>Mô tả</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="vertical-align-top">200</td>
                              <td>
                                <span>Response trả về thành công.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 200,
"email": {
    "phone": "1112223330",
    "phoneId": "374cdd3f-e28c-47dd-9f67-d63b15b0bea4",
}
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vertical-align-top">401</td>
                              <td>
                                <span>Response trả về lỗi nếu thiếu param <code>clientId</code> trong request.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 401,
"error": "Expired!"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vertical-align-top">403</td>
                              <td>
                                <span>Response trả về lỗi nếu param <code>clientId</code> không đúng.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 403,
"error": "Forbidden!"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vertical-align-top">400</td>
                              <td>
                                <span>Response trả về lỗi nếu thiếu param <code>serviceId</code> hoặc param <code>serviceId</code> không đúng.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 400,
"error": "Bad Request!"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="mt-5">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="bg-light-primary"
                  >
                    <Typography className={_.get(classes, 'heading')}>
                      <div>
                        <span className="api-doc-header-summary-button">GET</span>
                        <span className="api-doc-header-summary-title">{`/phone/code?clientId=<clientKey>&phoneId=<phoneId>`}</span>
                        <span className="api-doc-header-summary-title">{`Thực hiện lấy code trả về`}</span>
                        <br/>
                        <span className="api-doc-header-summary-title" style={{color: '#F1416C', fontSize: 'x-large'}}>
                          (Giãn cách thời gian giữa 2 lần gọi api liên tiếp tối thiểu là 5s, nếu không tài khoản sẽ bị khoá)
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="width-100">
                      <h4>Parameters</h4><hr/>
                      <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                          <thead>
                            <tr className='fw-bolder text-muted'>
                              <th className='width-200px'>Tên</th>
                              <th className='width-100px'>Kiểu</th>
                              <th className='width-100px'>Bắt buộc</th>
                              <th className='min-w-100px'>Mô tả</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>clientId</td>
                              <td>chuỗi</td>
                              <td>Có</td>
                              <td>Đây là id định danh cho mỗi User</td>
                            </tr>
                            <tr>
                              <td>phoneId</td>
                              <td>chuỗi</td>
                              <td>Có</td>
                              <td>Đây là số phoneId mà User muốn lấy code</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <span>Ví dụ: <code>https://api.winmail.shop/phone/code?clientId={userRentMail.key}&phoneId=374cdd3f-e28c-47dd-9f67-d63b15b0bea4</code></span>

                      <h4 className="mt-10">Response</h4><hr/>
                      <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                          <thead>
                            <tr className='fw-bolder text-muted'>
                              <th className='width-100px'>Code</th>
                              <th className='min-w-100px'>Mô tả</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="vertical-align-top">200</td>
                              <td>
                                <span>Response trả về thành công.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 200,
"result": {
    "phone": "1112223330",
    "phoneId": "374cdd3f-e28c-47dd-9f67-d63b15b0bea4",
    "code": "12345"
},
"status": "done"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vertical-align-top">401</td>
                              <td>
                                <span>Response trả về lỗi nếu thiếu param <code>clientId</code> trong request.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 401,
"error": "Expired!"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vertical-align-top">403</td>
                              <td>
                                <span>Response trả về lỗi nếu param <code>clientId</code> không đúng.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 403,
"error": "Forbidden!"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vertical-align-top">400</td>
                              <td>
                                <span>Response trả về lỗi nếu thiếu param <code>phone</code> trong request.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 400,
"error": "Bad Request!"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vertical-align-top">421</td>
                              <td>
                                <span>Response trả về lỗi nếu xảy ra lỗi khi lấy code TextNow</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 421,
"error": "Get code error!",
"status": "otp_error"
}`
                                      }
                                    </code>
                                  </pre>
                                  <pre>
                                    <code>
                                      {
`{
"code": 421,
"error": "Get code error!",
"status": "expired"
}`
                                      }
                                    </code>
                                  </pre>
                                  <pre>
                                    <code>
                                      {
`{
"code": 421,
"error": "Get code error!",
"status": "error"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <span style={{color: 'red'}}>(*) Đối với trường hợp response trả về thành công nhưng kết quả trả về chỉ có phone, phoneId mà không có code, ví dụ:</span><br/>
                        <pre>
                          <code>
{
  `{
    "code": 200,
    "result": {
        "phone": "1112223330",
        "phoneId": "374cdd3f-e28c-47dd-9f67-d63b15b0bea4"
    }
  }`
}
                          </code>
                        </pre>
                        <span style={{color: 'red'}}>thì vui lòng tiếp tục gọi api cho đến khi lấy được code hoặc đến khi nào xảy ra lỗi thì dừng.</span>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div className='notice bg-light-success rounded border-primary border border-dashed p-6 mt-5'>
          <div>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Hướng dẫn sử dụng API mua mail:</h4>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="bg-light-primary"
                >
                  <Typography className={_.get(classes, 'heading')}>
                    <span className="api-doc-header-summary-button">GET</span>
                    <span className="api-doc-header-summary-title">{`/services/all?clientId=<clientId>&type=mail`}</span>
                    <span className="api-doc-header-summary-title">{`Lấy thông tin danh sách các service Mail`}</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="width-100">
                    <h4>Parameters</h4><hr/>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bolder text-muted'>
                            <th className='width-100px'>Tên</th>
                            <th className='width-100px'>Kiểu</th>
                            <th className='width-100px'>Bắt buộc</th>
                            <th className='min-w-100px'>Mô tả</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>clientId</td>
                            <td>chuỗi</td>
                            <td>Có</td>
                            <td>Đây là id định danh cho mỗi User</td>
                          </tr>
                          <tr>
                            <td>type</td>
                            <td>chuỗi</td>
                            <td>Không</td>
                            <td>Loại service. Ví dụ: phone, mail...</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <span>Ví dụ: <code>https://api.winmail.shop/services/all?clientId={userRentMail.key}&type=mail</code></span>

                    <h4 className="mt-10">Response</h4><hr/>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bolder text-muted'>
                            <th className='width-100px'>Code</th>
                            <th className='min-w-100px'>Mô tả</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="vertical-align-top">200</td>
                            <td>
                              <span>Response trả về thành công.</span>
                              <div>
                                Ví dụ:
                                <pre>
                                  <code>
                                    {
`{
"code": 200,
"services": [
  {
    "name": "Gmail 24h",
    "key": "gmail24h"
  },
  {
    {
      "name": "Gmail live",
      "key": "gmail_live"
    }
  }
]
}`
                                    }
                                  </code>
                                </pre>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="vertical-align-top">401</td>
                            <td>
                              <span>Response trả về lỗi nếu thiếu param <code>clientId</code> trong request.</span>
                              <div>
                                Ví dụ:
                                <pre>
                                  <code>
                                    {
`{
"code": 401,
"error": "Expired!"
}`
                                    }
                                  </code>
                                </pre>
                              </div>
                            </td>
                          </tr>
                          <tr>
                              <td className="vertical-align-top">403</td>
                              <td>
                                <span>Response trả về lỗi nếu param <code>clientId</code> không đúng.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 403,
"error": "Forbidden!"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <div className="mt-5">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="bg-light-primary"
                  >
                    <Typography className={_.get(classes, 'heading')}>
                      <span className="api-doc-header-summary-button">GET</span>
                      <span className="api-doc-header-summary-title">{`/email/rent?clientId=<clientKey>&serviceId=<serviceKey>`}</span>
                      <span className="api-doc-header-summary-title">{`Thực hiện mua mail`}</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="width-100">
                      <h4>Parameters</h4><hr/>
                      <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                          <thead>
                            <tr className='fw-bolder text-muted'>
                              <th className='width-100px'>Tên</th>
                              <th className='width-100px'>Kiểu</th>
                              <th className='width-150px'>Bắt buộc</th>
                              <th className='width-100px'>Mặc định</th>
                              <th className='min-w-100px'>Mô tả</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>clientId</td>
                              <td>chuỗi</td>
                              <td>Có</td>
                              <td></td>
                              <td>Đây là id định danh cho mỗi User</td>
                            </tr>
                            <tr>
                              <td>serviceId</td>
                              <td>chuỗi</td>
                              <td>Có</td>
                              <td></td>
                              <td>Đây là loại mail cần mua</td>
                            </tr>
                            <tr>
                              <td>type</td>
                              <td>chuỗi</td>
                              <td>Có (chỉ đối với type là 'gmail')</td>
                              <td></td>
                              <td>Loại gmail cần mua (có 5 loại: facebook, apple, tiktok, offer, other)</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <span>Ví dụ: <code>https://api.winmail.shop/email/rent?clientId={userRentMail.key}&serviceId=gmail&type=facebook</code></span>

                      <h4 className="mt-10">Response</h4><hr/>
                      <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                          <thead>
                            <tr className='fw-bolder text-muted'>
                              <th className='width-100px'>Code</th>
                              <th className='min-w-100px'>Mô tả</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="vertical-align-top">200</td>
                              <td>
                                <span>Response trả về thành công.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 200,
"emails": [
  {
    "email": "robtelfenbein@gmail.com",
    "password": "abcxyz"
  }
]
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vertical-align-top">401</td>
                              <td>
                                <span>Response trả về lỗi nếu thiếu param <code>clientId</code> trong request.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 401,
"error": "Expired!"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vertical-align-top">403</td>
                              <td>
                                <span>Response trả về lỗi nếu param <code>clientId</code> không đúng.</span>
                                <div>
                                  Ví dụ:
                                  <pre>
                                    <code>
                                      {
`{
"code": 403,
"error": "Forbidden!"
}`
                                      }
                                    </code>
                                  </pre>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <iframe
          style={
            {
              width: '100%',
              height: '800px'
            }
          }
          src='https://docs.google.com/document/d/1ybfV_losQOmrymWGklArlwc2fmpRdxPKKSQOsKLsLss/edit?usp=sharing'></iframe>
        <h2>Hướng dẫn sử dụng API Winmail.shop trên Maxcare</h2>
        <iframe
          style={
            {
              width: '100%',
              height: '800px'
            }
          }
          src='https://docs.google.com/document/d/1WhPFZtvP9zHaESWLvLe1HSPKk4VXb-M5Ze-dcW_PyTU/edit?usp=sharing'></iframe>
      </div>
    </div>
  )
}

export default RentMailApiGuide
