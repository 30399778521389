import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import { put, select, takeLatest } from 'redux-saga/effects'
import { ActionWithPayload } from '../../auth'
import { MailModel } from '../models/MailModel'
import { ServiceModel } from '../models/ServiceModel'
import { DataChartType,
  createRentMail,
  getMails,
  getServices,
  getTransaction,
  SearchMailParamsType,
  SearchTransParamsType,
  SearchMailsRealtimeParamsType,
  getMailsRealtime,
  DataChartUserType,
  SearchPageAdminInfoParamsType,
  updateUserInfo,
  createSellMail,
  getBuyMails,
  DataChartPhoneType,
  createReRentMail,
  getAdminUserInfo,
  getAdminPhoneInfo, 
  AdminUserPhoneType,
  getConfig,
  AdminConfigType,
  resetAllThread,
  releaseWaitTextNow,
  AdminUserMailType,
  getAdminMailInfo,
  AdminStatisticalType,
  getAdminStatistical,
  downloadAdminStatistical,
  getServicesRealtime,
  updateService,
  updateConfig,
  ImportAccountType,
  importFacebookAccount,
  AdminUserFbType,
  getAdminFbInfo,
  ApiLinkWebsiteConfigType,
  insertApiLinkWebsite,
  getApiLinkWebsiteConfig,
  updateApiLinkWebsite,
  deleteApiLinkWebsite,
  ServicesQuantity,
  getServicesQuantity,
  AdminUserTxType,
  getAdminTxInfo} from './RentMailCRUD'
import { actionTypesAuth, actions } from '../../auth/redux/AuthRedux';
import _ from 'lodash'
import { TransactionModel } from '../models/TransactionModel'
import { UserRentMailModel } from '../models/UserRentMailModel'
import moment from 'moment'
import clipboard from 'clipboardy';
import { toIsoString } from '../common/common'
import { AccountFacebookModel } from '../models/AccountFacebookModel'
import { AccountTextnowModel } from '../models/AccountTextnowModel'

export const actionTypes = {
  GetServices: '[GetServices] Action',
  FetchServices: '[FetchServices] Action',
  ServicesLoaded: '[ServicesLoaded] Action',
  GetServicesRealtime: '[GetServicesRealtime] Action',
  GetServicesRealtimeLoaded: '[GetServicesRealtimeLoaded] Action',
  CreateRentMail: '[CreateRentMail] Action',
  CreateReRentMail: '[CreateReRentMail] Action',
  CreateSellMail: '[CreateSellMail] Action',
  SetPercentageSellMail: '[SetPercentageSellMail] Action',
  CreatedEmailLoaded: '[CreatedEmailLoaded] Action',
  RefreshRentMailCreatedSuccess: '[RefreshRentMailCreatedSuccess] Action',
  RefreshTransactionCreatedSuccess: '[RefreshTransactionCreatedSuccess] Action',
  GetMails: '[GetMails] Action',
  GetBuyMails: '[GetBuyMails] Action',
  GetMailsLoaded: '[GetMailsLoaded] Action',
  GetMailsHistory: '[GetMailsHistory] Action',
  GetMailsHistoryLoaded: '[GetMailsHistoryLoaded] Action',
  GetBuyMailsHistory: '[GetBuyMailsHistory] Action',
  GetTrans: '[GetTrans] Action',
  GetTransLoaded: '[GetTransLoaded] Action',
  GetMailsRealtime: '[GetMailsRealtime] Action',
  GetMailsRealtimeLoaded: '[GetMailsRealtimeLoaded] Action',
  GetAdminUserInfo: '[GetAdminUserInfo] Action',
  GetAdminUserInfoLoaded: '[GetAdminUserInfoLoaded] Action',
  GetAdminUserPhoneInfo: '[GetAdminUserPhoneInfo] Action',
  GetAdminUserPhoneInfoLoaded: '[GetAdminUserPhoneInfoLoaded] Action',
  GetAdminUserMailInfoLoaded: '[GetAdminUserMailInfoLoaded] Action',
  GetAdminUserMailInfo: '[GetAdminUserMailInfo] Action',
  GetAdminUserFbInfo: '[GetAdminUserFbInfo] Action',
  GetAdminUserFbInfoLoaded: '[GetAdminUserFbInfoLoaded] Action',
  GetAdminUserTxInfo: '[GetAdminUserTxInfo] Action',
  GetAdminUserTxInfoLoaded: '[GetAdminUserTxInfoLoaded] Action',
  GetAdminStatistical: '[GetAdminStatistical] Action',
  GetAdminStatisticalLoaded: '[GetAdminStatisticalLoaded] Action',
  ResetAdminStatisticalInfo: '[ResetAdminStatisticalInfo] Action',
  DownloadAdminStatistical: '[DownloadAdminStatistical] Action',
  DownloadAdminStatisticalLoaded: '[DownloadAdminStatisticalLoaded] Action',
  UpdateUserInfo: '[UpdateUserInfo] Action',
  UpdatedUserInfoLoaded: '[UpdatedUserInfoLoaded] Action',
  DownloadEmailsTransaction: '[DownloadEmailsTransaction] Action',
  DownloadEmailsTransactionCompleted: '[DownloadEmailsTransactionCompleted] Action',
  CopyBuyEmails: '[CopyBuyEmails] Action',
  CopyBuyEmailsCompleted: '[CopyBuyEmailsCompleted] Action',
  GetConfig: '[GetConfig] Action',
  GetConfigLoaded: '[GetConfigLoaded] Action',
  UpdateConfig: '[UpdateConfig] Action',
  UpdateConfigCompleted: '[UpdateConfigCompleted] Action',
  ResetAllThread: '[ResetAllThread] Action',
  ResetAllThreadDone: '[ResetAllThreadDone] Action',
  ReleaseWaitPhone: '[ReleaseWaitPhone] Action',
  ReleaseWaitPhoneDone: '[ReleaseWaitPhoneDone] Action',
  UpdateService: '[UpdateService] Action',
  UpdateServiceCompleted: '[UpdateServiceCompleted] Action',
  ImportFacebookAccount: '[ImportFacebookAccount] Action',
  ImportFacebookAccountCompleted: '[ImportFacebookAccountCompleted] Action',
  IsImportingFacebookAccount: '[IsImportingFacebookAccount] Action',
  InsertApiLinkWebsiteConfig: '[InsertApiLinkWebsiteConfig] Action',
  UpdateApiLinkWebsiteConfig: '[UpdateApiLinkWebsiteConfig] Action',
  UpsertApiLinkWebsiteConfigComplete: '[UpsertApiLinkWebsiteConfigComplete] Action',
  SetUpsertApiLinkWebsiteConfigComplete: '[SetUpsertApiLinkWebsiteConfigComplete] Action',
  GetApiLinkWebsiteConfigs: '[GetApiLinkWebsiteConfigs] Action',
  ApiLinkWebsiteConfigsLoaded: '[ApiLinkWebsiteConfigsLoaded] Action',
  DeleteApiLinkWebsiteConfig: '[DeleteApiLinkWebsiteConfig] Action',
  GetServicesQuantity: '[GetServicesQuantity] Action',
  GetServicesQuantityLoaded: '[GetServicesQuantityLoaded] Action',
}

const initialServicesState: IServiceState = {
  services: [],
  serviceType: '',
  serviceTypeCreated: '',
  serviceIdCreated: '',
  userId: '',
  rentMailCreatedSuccess: true,
  transactionCreatedSuccess: false,
  rentMailsCreated: {
    lstRentMailCreated: [],
    total: 0
  },
  rentMailsHistory: {
    lstRentMail: [],
    total: 0,
    dataChart: []
  },
  transaction: {
    lstTrans: [],
    dataChart: [],
    total: 0,
    dataCostByType: [],
  },
  pageAdminUserInfo: {
    lstUsers: [],
    total: 0,
    dataChart: {rechargeChart: [], doneMailChart: [], expiredMailChart: []}
  },
  pageAdminUserPhoneInfo: {
    lstUserPhones: [],
    total: 0,
  },
  pageAdminUserMailInfo: {
    lstUserMails: [],
    total: 0,
  },
  pageAdminUserFbInfo: {
    lstUserFbs: [],
    total: 0,
  },
  pageAdminUserTxInfo: {
    lstUserTxs: [],
    total: 0,
  },
  pageAdminStatistical: {},
  config: {},
  importFacebookAccountRsl: {
    completed: 0,
    error: 0,
  },
  apiLinkWebsiteConfigs: [],
  isUpsertApiLinkWebsiteConfigSuccess: false,
  servicesQuantity: {
    facebookOtp6: 0,
    facebookOtp5: 0,
    winFacebookOtp6: 0,
    winFacebookOtp5: 0
  }
}

export interface IServiceState {
  services?: ServiceModel[],
  serviceType?: string,
  serviceTypeCreated?: string,
  serviceIdCreated?: string,
  numEmailCreated?: number,
  requestMailTypeCreated?: string,
  userId?: string,
  rentMailCreatedSuccess: boolean,
  transactionCreatedSuccess: boolean,
  rentMailsCreated: {
    lstRentMailCreated: MailModel[] | AccountFacebookModel[],
    total: number
  },
  rentMailsHistory: {
    lstRentMail: MailModel[],
    total: number,
    dataChart: DataChartPhoneType[]
  },
  transaction: {
    lstTrans: TransactionModel[],
    total: number,
    dataChart: DataChartType[],
    dataCostByType: {type: string, total: number}[],
  },
  pageAdminUserInfo: {
    lstUsers: UserRentMailModel[],
    total: number,
    dataChart: {rechargeChart: DataChartType[], doneMailChart: DataChartType[], expiredMailChart: DataChartType[]},
  },
  pageAdminUserPhoneInfo: {
    lstUserPhones: AdminUserPhoneType[],
    total: number,
  }
  pageAdminUserMailInfo?: {
    lstUserMails: AdminUserMailType[],
    total: number,
  },
  pageAdminUserFbInfo: {
    lstUserFbs: AdminUserFbType[],
    total: number,
  },
  pageAdminUserTxInfo: {
    lstUserTxs: AdminUserTxType[],
    total: number,
  },
  pageAdminStatistical: AdminStatisticalType,
  config: AdminConfigType,
  importFacebookAccountRsl: ImportAccountType,
  apiLinkWebsiteConfigs: ApiLinkWebsiteConfigType[],
  isUpsertApiLinkWebsiteConfigSuccess: boolean,
  servicesQuantity?: ServicesQuantity
};



export const actionsRentMails = {
  getServices: (type?: string | string[]) => ({type: actionTypes.GetServices, payload: {serviceType: type}}),
  fetchServices: () => ({type: actionTypes.FetchServices}),
  fulfillServices: (services: ServiceModel[]) => ({type: actionTypes.ServicesLoaded, payload: {services}}),
  getServicesRealtime: (serviceKeys: string[]) => ({type: actionTypes.GetServicesRealtime, payload: {serviceKeys} }),
  getServicesRealtimeLoaded: (servicesRealtime: ServiceModel[]) => ({type: actionTypes.GetServicesRealtimeLoaded, payload: {servicesRealtime} }),
  createRentMail: (serviceId: string) => ({type: actionTypes.CreateRentMail, payload: {serviceIdCreated: serviceId}}),
  createReRentMail: (phoneId: string, serviceId: string, phone: string) => ({
    type: actionTypes.CreateReRentMail,
    payload: {serviceIdCreated: serviceId, phoneCreated: phone, phoneIdCreated: phoneId}}),
  createSellMail: (type: string, serviceId: string, numEmail: number, requestMailType: string) => ({type: actionTypes.CreateSellMail, payload: { serviceTypeCreated: type, serviceIdCreated: serviceId, numEmailCreated: numEmail, requestMailTypeCreated: requestMailType }}),
  setPercentageSellMail: (percentage: number) => ({type: actionTypes.SetPercentageSellMail, payload: { percentageSellMail: percentage }}),
  fulfillCreatedRentMail: (type: string, email: MailModel[]) => ({type: actionTypes.CreatedEmailLoaded, payload: {type, rentMailCreated: email}}),
  refreshRentMailCreatedSuccess: () => ({type: actionTypes.RefreshRentMailCreatedSuccess}),
  refreshTransactionCreatedSuccess: () => ({type: actionTypes.RefreshTransactionCreatedSuccess}),
  getMails: (searchMailParams: SearchMailParamsType) => ({type: actionTypes.GetMails, payload: {searchMailParams}}),
  getBuyMails: (searchMailParams: SearchMailParamsType) => ({type: actionTypes.GetBuyMails, payload: {searchMailParams}}),
  fulfillGetRentMails: (result: {mails: MailModel[] | AccountFacebookModel[], total: number}) => ({type: actionTypes.GetMailsLoaded, payload: {result}}),
  getMailsHistory: (searchMailParams: SearchMailParamsType) => ({type: actionTypes.GetMailsHistory, payload: {searchMailParams}}),
  fulfillGetMailsHistory:
    (result: {mails: MailModel[], total: number, dataChart: DataChartType[]}) => ({type: actionTypes.GetMailsHistoryLoaded, payload: {result}}),
  getBuyMailsHistory: (searchMailParams: SearchMailParamsType) => ({type: actionTypes.GetBuyMailsHistory, payload: {searchMailParams}}),
  getTrans: (searchTransParams: SearchTransParamsType) => ({type: actionTypes.GetTrans, payload: {searchTransParams}}),
  fulfillGetTrans: (result: {
    trans: TransactionModel[],
    total: number
    dataChart: DataChartType}) => ({type: actionTypes.GetTransLoaded, payload: {result}}),
  getMailsRealtime: (searchMailsRealtimeParams: SearchMailsRealtimeParamsType) => ({type: actionTypes.GetMailsRealtime, payload: {searchMailsRealtimeParams}}),
  fulfillGetMailsRealtime: (result: {
    mailsRealtime: MailModel[]
  }) => ({type: actionTypes.GetMailsRealtimeLoaded, payload: {result}}),
  getAdminUserInfo: (searchPageAdminInfoParams: SearchPageAdminInfoParamsType) => ({type: actionTypes.GetAdminUserInfo, payload: {searchPageAdminInfoParams}}),
  fulfillGetAdminUserInfo: (result: {
    users: UserRentMailModel[];
    dataChart: DataChartUserType;
    total: number;
  }) => ({type: actionTypes.GetAdminUserInfoLoaded, payload: {result}}),
  getAdminUserPhoneInfo: (searchPageAdminUserPhoneInfoParams: SearchPageAdminInfoParamsType) => ({type: actionTypes.GetAdminUserPhoneInfo, payload: {searchPageAdminUserPhoneInfoParams}}),
  fulfillGetAdminUserPhoneInfo: (result: AdminUserPhoneType) => ({type: actionTypes.GetAdminUserPhoneInfoLoaded, payload: {result}}),
  fulfillGetAdminUserMailInfo: (result: AdminUserMailType) => ({type: actionTypes.GetAdminUserMailInfoLoaded, payload: {result}}),
  getAdminUserMailInfo: (searchPageAdminUserPhoneInfoParams: SearchPageAdminInfoParamsType) => ({type: actionTypes.GetAdminUserMailInfo, payload: {searchPageAdminUserPhoneInfoParams}}),
  getAdminUserFbInfo: (searchPageAdminUserPhoneInfoParams: SearchPageAdminInfoParamsType) => ({type: actionTypes.GetAdminUserFbInfo, payload: {searchPageAdminUserPhoneInfoParams}}),
  fulfillGetAdminUserFbInfo: (result: AdminUserFbType) => ({type: actionTypes.GetAdminUserFbInfoLoaded, payload: {result}}),
  getAdminUserTxInfo: (searchPageAdminUserPhoneInfoParams: SearchPageAdminInfoParamsType) => ({type: actionTypes.GetAdminUserTxInfo, payload: {searchPageAdminUserPhoneInfoParams}}),
  fulfillGetAdminUserTxInfo: (result: AdminUserTxType) => ({type: actionTypes.GetAdminUserTxInfoLoaded, payload: {result}}),
  getAdminStatistical: (searchParams: {fromDate: string, toDate: string, email: string}) => ({type: actionTypes.GetAdminStatistical, payload: {searchParams}}),
  fulfillGetAdminStatistical: (result: AdminStatisticalType) => ({type: actionTypes.GetAdminStatisticalLoaded, payload: {result}}),
  resetAdminStatisticalInfo: () => ({type: actionTypes.ResetAdminStatisticalInfo}),
  downloadAdminStatistical: (searchParams: {fromDate: string, toDate: string, email: string, export: boolean}) => ({type: actionTypes.DownloadAdminStatistical, payload: {searchParams}}),
  fulfillDownloadAdminStatistical: () => ({type: actionTypes.DownloadAdminStatisticalLoaded}),
  updateUserInfo: (userUpdateInfo: {
    coin?: number,
    password?: string,
    disabled?: boolean,
    numWait?: number,
    otpLength?: string,
    cancelPhonePermission?: boolean,
    textnowFromInternal?: boolean,
    isChangeClientId?: boolean,
  }) => ({type: actionTypes.UpdateUserInfo, payload: {userUpdateInfo}}),
  fulfillUserUpdatedByAdmin: (userUpdatedInfo: UserRentMailModel) => ({type: actionTypes.UpdatedUserInfoLoaded, payload: {userUpdatedInfo}}),
  DownloadEmailsTransaction: (transactionType: string, transaction: string) => ({type: actionTypes.DownloadEmailsTransaction, payload: {transactionType, transaction}}),
  DownloadEmailsTransactionCompleted: () => ({type: actionTypes.DownloadEmailsTransactionCompleted}),
  CopyBuyEmails: (transaction: string) => ({type: actionTypes.CopyBuyEmails, payload: {transaction}}),
  CopyBuyEmailsCompleted: () => ({type: actionTypes.CopyBuyEmailsCompleted}),
  getConfig: () => ({type: actionTypes.GetConfig}),
  fulfillConfig: (config: AdminConfigType) => ({type: actionTypes.GetConfigLoaded, payload: {config}}),
  updateConfig: (config: AdminConfigType) => ({type: actionTypes.UpdateConfig, payload: {configUpdateInfo: config}}),
  updateConfigCompleted: () => ({type: actionTypes.UpdateConfigCompleted}),
  resetAllThread: () => ({type: actionTypes.ResetAllThread}),
  resetAllThreadDone: () => ({type: actionTypes.ResetAllThreadDone}),
  releaseWaitPhone: () => ({type: actionTypes.ReleaseWaitPhone}),
  releaseWaitPhoneDone: () => ({type: actionTypes.ReleaseWaitPhoneDone}),
  updateService: (updateServiceInfo: {service: string, disabled?: boolean, isLinkApi?: boolean}) => ({type: actionTypes.UpdateService, payload: {updateServiceInfo}}),
  updateServiceCompleted: () => ({type: actionTypes.UpdateServiceCompleted}),
  importFacebookAccount: (accounts: AccountFacebookModel[]) => ({type: actionTypes.ImportFacebookAccount, payload: {facebookAccountsParam: accounts}}),
  importFacebookAccountCompleted: (importFacebookRsl: ImportAccountType) => ({type: actionTypes.ImportFacebookAccountCompleted, payload: {importFacebookRsl}}),
  setImportingFacebookAccount: (isImporting: boolean) => ({type: actionTypes.IsImportingFacebookAccount, payload: {isImporting}}),
  insertApiLinkWebsiteConfig: (apiLinkWebsiteConfig: ApiLinkWebsiteConfigType) => ({type: actionTypes.InsertApiLinkWebsiteConfig, payload: { apiLinkWebsiteConfig }}),
  updateApiLinkWebsiteConfig: (apiLinkWebsiteConfig: any) => ({type: actionTypes.UpdateApiLinkWebsiteConfig, payload: { apiLinkWebsiteConfig }}),
  upsertApiLinkWebsiteConfigComplete: () => ({type: actionTypes.UpsertApiLinkWebsiteConfigComplete}),
  setUpsertApiLinkWebsiteConfigComplete: (isUpsertApiLinkWebsiteConfigSuccess: boolean) => ({type: actionTypes.SetUpsertApiLinkWebsiteConfigComplete, payload: { isUpsertApiLinkWebsiteConfigSuccess }}),
  getApiLinkWebsiteConfigs: () => ({ type: actionTypes.GetApiLinkWebsiteConfigs }),
  fulfillApiLinkWebsiteConfigs: (apiLinkWebsiteConfigs: ApiLinkWebsiteConfigType[]) => ({ type: actionTypes.ApiLinkWebsiteConfigsLoaded, payload: { apiLinkWebsiteConfigs } }),
  deleteApiLinkWebsiteConfig: (apiLinkWebsiteId: string) => ({type: actionTypes.DeleteApiLinkWebsiteConfig, payload: { apiLinkWebsiteId }}),
  getServicesQuantity: () => ({ type: actionTypes.GetServicesQuantity }),
  fulfillServicesQuantity: (servicesQuantity: ServicesQuantity) => ({ type: actionTypes.GetServicesQuantityLoaded, payload: { servicesQuantity } })
}

let lastAction: any;
export const reducer = persistReducer(
  {storage, key: 'v100-rentMail-rentmail', whitelist: ['services', 'rentMailsCreated']},
  (state: IServiceState = initialServicesState, action: ActionWithPayload<IServiceState>) => {
    if (_.isEqual(lastAction, action)) {
      action.type = "";
    }
    lastAction = action;
    switch (action.type) {
      case actionTypes.GetServices: {
        return {...state, ...{serviceType: action.payload?.serviceType, services: [], [`isLoading_${actionTypes.GetServices}`]: true}}
      }
      case actionTypes.ServicesLoaded: {
        return {...state, ...{services: action.payload?.services || [], [`isLoading_${actionTypes.GetServices}`]: false}}
      }
      case actionTypes.GetServicesRealtime: {
        // @ts-ignore
        return {...state, serviceKeys: action.payload?.serviceKeys || []}
      }
      case actionTypes.GetServicesRealtimeLoaded: {
        // @ts-ignore
        const { servicesRealtime } = action.payload;
        const services = _.cloneDeep(_.get(state, 'services', []));
        services.forEach(serv => {
          Object.assign(serv, _.find(servicesRealtime, s => s.key === serv.key) || {});
        });
        return {...state, services};
      }
      case actionTypes.CreateRentMail: {
        return {...state,...{rentMailsCreated: state.rentMailsCreated,
          serviceIdCreated: action.payload?.serviceIdCreated,
          userId: action.payload?.userId, [`isLoading_${actionTypes.CreateRentMail}`]: true}}
      }
      case actionTypes.CreateReRentMail: {
        return {...state,...{rentMailsCreated: state.rentMailsCreated,
          serviceIdCreated: action.payload?.serviceIdCreated,
          // @ts-ignore
          phoneCreated: action.payload?.phoneCreated,
          // @ts-ignore
          phoneIdCreated: action.payload?.phoneIdCreated,
          userId: action.payload?.userId, [`isLoading_${actionTypes.CreateRentMail}`]: true}}
      }
      case actionTypes.CreateSellMail: {
        return {...state,...{rentMailsCreated: state.rentMailsCreated,
          serviceTypeCreated: action.payload?.serviceTypeCreated,
          serviceIdCreated: action.payload?.serviceIdCreated,
          numEmailCreated: action.payload?.numEmailCreated,
          requestMailTypeCreated: action.payload?.requestMailTypeCreated,
          userId: action.payload?.userId, [`isLoading_${actionTypes.CreateRentMail}`]: true}}
      }
      case actionTypes.SetPercentageSellMail: {
        // @ts-ignore
        return {...state, percentageSellMail: action.payload?.percentageSellMail};
      }
      case actionTypes.CreatedEmailLoaded: {
        const lstRentMailCreatedTmp = state.rentMailsCreated.lstRentMailCreated.slice();
        let total = state.rentMailsCreated.total;
        let rentMailCreatedSuccess = false;
        let transactionCreatedSuccess = false;
        // @ts-ignore
        if (!_.isEmpty(action.payload?.rentMailCreated)) {
          // @ts-ignore
          if (_.get(action.payload, 'type') === 'phone' ) {
            // @ts-ignore
            lstRentMailCreatedTmp.unshift(action.payload?.rentMailCreated[0]);
            total += 1;
            rentMailCreatedSuccess = true;
          } else {
            rentMailCreatedSuccess = true;
            transactionCreatedSuccess = true;
          }
        }
        return {...state, ...{rentMailsCreated: {
          lstRentMailCreated: lstRentMailCreatedTmp,
          total}, rentMailCreatedSuccess, transactionCreatedSuccess,
          [`isLoading_${actionTypes.CreateRentMail}`]: false},
          percentageSellMail: 0}
      }
      case actionTypes.RefreshRentMailCreatedSuccess: {
        return {...state, ...{rentMailCreatedSuccess: true}}
      }
      case actionTypes.RefreshTransactionCreatedSuccess: {
        return {...state, ...{transactionCreatedSuccess: false}}
      }
      case actionTypes.GetMails: {
        // @ts-ignore
        return {...state, ...{rentMailsCreated: {lstRentMailCreated: [], total: 0},
          // @ts-ignore
          searchMailParams: action.payload?.searchMailParams,
          [`isLoading_${actionTypes.GetMails}`]: true}};
      }
      case actionTypes.GetBuyMails: {
        // @ts-ignore
        return {...state, ...{rentMailsCreated: {lstRentMailCreated: [], total: 0},
          // @ts-ignore
          searchMailParams: action.payload?.searchMailParams,
          [`isLoading_${actionTypes.GetMails}`]: true}};
      }
      case actionTypes.GetMailsLoaded: {
        // @ts-ignore
        const lstRentMailCreatedTmp = state.rentMailsCreated.lstRentMailCreated.slice().concat(action.payload?.result.emails || action.payload?.result.accounts || []) as AccountFacebookModel[];
        // @ts-ignore
        const total = action.payload?.result.total || 0;
        return {...state, ...{
          rentMailsCreated: {lstRentMailCreated: lstRentMailCreatedTmp, total},
          [`isLoading_${actionTypes.GetMails}`]: false}};
      }
      case actionTypes.GetMailsRealtime: {
        // @ts-ignore
        return {...state, ...{rentMailsRealtime: [],
          // @ts-ignore
          searchMailsRealtimeParams: action.payload?.searchMailsRealtimeParams}};
      }
      case actionTypes.GetMailsRealtimeLoaded: {
        const lstRentMailCreatedTmp = state.rentMailsCreated.lstRentMailCreated.slice() as MailModel[];
        // @ts-ignore
        const rentMailsRealtime = action.payload?.result.mailsRealtime;
        _.forEach(lstRentMailCreatedTmp, mail => {
          const mailRt = _.find(rentMailsRealtime, m => m._id === mail._id);
          if (mailRt) {
            mail.status = mailRt.status;
            mail.message = mailRt.message;
            mail.code = mailRt.code;
            mail.audio = _.get(mailRt, 'audio');
            mail.sublet = _.get(mailRt, 'sublet', false);
          }
        });
        // @ts-ignore
        return {...state, ...{rentMailsCreated:
          {lstRentMailCreated: lstRentMailCreatedTmp, total: _.get(state, 'rentMailsCreated.total', 0)}}};
      }
      case actionTypes.GetMailsHistory: {
        // @ts-ignore
        return {...state, ...{rentMailsHistory: {lstRentMail: [], total: 0, dataChart: []},
          // @ts-ignore
          searchMailParams: action.payload?.searchMailParams,
          [`isLoading_${actionTypes.GetMailsHistory}`]: true}};
      }
      case actionTypes.GetMailsHistoryLoaded: {
        // @ts-ignore
        const lstRentMail = state.rentMailsHistory.lstRentMail.slice().concat(action.payload?.result.emails || []);
        // @ts-ignore
        const total = action.payload?.result.total || 0;
        // @ts-ignore
        const dataChart = action.payload?.result.dataChart || [];
        return {...state, ...{
          rentMailsHistory: {lstRentMail, total, dataChart},
          [`isLoading_${actionTypes.GetMailsHistory}`]: false}};
      }
      case actionTypes.GetTrans: {
        return {...state, ...{transaction: {lstTrans: [],
            total: 0,
            dataChart: [],
            dataCostByType: []},
          // @ts-ignore
          searchTransParams: action.payload?.searchTransParams,
          [`isLoading_${actionTypes.GetTrans}`]: true}};
      }
      case actionTypes.GetTransLoaded: {
        // @ts-ignore
        const lstTrans = action.payload?.result.trans || [];
        // @ts-ignore
        const total = action.payload?.result.total || 0;
        // @ts-ignore
        const dataChart = action.payload?.result.dataChart || [];
        // @ts-ignore
        const dataCostByType = action.payload?.result.dataCostByType || [];
        return {...state, ...{transaction: {lstTrans, total, dataChart, dataCostByType},
          [`isLoading_${actionTypes.GetTrans}`]: false}};
      }
      case actionTypes.GetAdminUserInfo: {
        // @ts-ignore
        return {...state, ...{ pageAdminUserInfo: {
            lstUsers: [],
            total: 0,
            dataChart: {rechargeChart: [], doneMailChart: [], expiredMailChart: []},
          },
          // @ts-ignore
          searchPageAdminInfoParams: action.payload?.searchPageAdminInfoParams,
          [`isLoading_${actionTypes.GetAdminUserInfo}`]: true}};
      }
      case actionTypes.GetAdminUserInfoLoaded: {
        // @ts-ignore
        const lstUsers = action.payload?.result.users || [];
        // @ts-ignore
        const total = action.payload?.result.total || 0;
        // @ts-ignore
        const dataChart = action.payload?.result.dataChart;
        return {...state, ...{pageAdminUserInfo: {lstUsers, total, dataChart},
          [`isLoading_${actionTypes.GetAdminUserInfo}`]: false}};
      }
      case actionTypes.GetAdminUserPhoneInfo: {
        // @ts-ignore
        return {...state, ...{ pageAdminUserPhoneInfo: {
            lstUserPhones: [],
            total: 0,
          },
          // @ts-ignore
          searchPageAdminUserPhoneInfoParams: action.payload?.searchPageAdminUserPhoneInfoParams,
          [`isLoading_${actionTypes.GetAdminUserPhoneInfo}`]: true}};
      }
      case actionTypes.GetAdminUserPhoneInfoLoaded: {
        // @ts-ignore
        const lstUserPhones = action.payload?.result.userPhones || [];
        // @ts-ignore
        const total = action.payload?.result.total || 0;
        return {...state, ...{pageAdminUserPhoneInfo: {lstUserPhones, total},
          [`isLoading_${actionTypes.GetAdminUserPhoneInfo}`]: false}};
      }
      case actionTypes.GetAdminUserMailInfo: {
        // @ts-ignore
        return {...state, ...{ pageAdminUserMailInfo: {
            lstUserMails: [],
            total: 0,
          },
          // @ts-ignore
          searchPageAdminUserPhoneInfoParams: action.payload?.searchPageAdminUserPhoneInfoParams,
          [`isLoading_${actionTypes.GetAdminUserMailInfo}`]: true}};
      }
      case actionTypes.GetAdminUserMailInfoLoaded: {
        // @ts-ignore
        const lstUserMails = action.payload?.result.userMails || [];
        // @ts-ignore
        const total = action.payload?.result.total || 0;
        return {...state, ...{pageAdminUserMailInfo: {lstUserMails, total},
          [`isLoading_${actionTypes.GetAdminUserMailInfo}`]: false}};
      }
      case actionTypes.GetAdminUserFbInfo: {
        // @ts-ignore
        return {...state, ...{ pageAdminUserFbInfo: {
            lstUserFbs: [],
            total: 0,
          },
          // @ts-ignore
          searchPageAdminUserPhoneInfoParams: action.payload?.searchPageAdminUserPhoneInfoParams,
          [`isLoading_${actionTypes.GetAdminUserFbInfo}`]: true}};
      }
      case actionTypes.GetAdminUserFbInfoLoaded: {
        // @ts-ignore
        const lstUserFbs = action.payload?.result.userFbs || [];
        // @ts-ignore
        const total = action.payload?.result.total || 0;
        return {...state, ...{pageAdminUserFbInfo: {lstUserFbs, total},
          [`isLoading_${actionTypes.GetAdminUserFbInfo}`]: false}};
      }
      case actionTypes.GetAdminUserTxInfo: {
        // @ts-ignore
        return {...state, ...{ pageAdminUserTxInfo: {
            lstUserTxs: [],
            total: 0,
          },
          // @ts-ignore
          searchPageAdminUserPhoneInfoParams: action.payload?.searchPageAdminUserPhoneInfoParams,
          [`isLoading_${actionTypes.GetAdminUserTxInfo}`]: true}};
      }
      case actionTypes.GetAdminUserTxInfoLoaded: {
        // @ts-ignore
        const lstUserTxs = action.payload?.result.userTxs || [];
        // @ts-ignore
        const total = action.payload?.result.total || 0;
        return {...state, ...{pageAdminUserTxInfo: {lstUserTxs, total},
          [`isLoading_${actionTypes.GetAdminUserTxInfo}`]: false}};
      }
      case actionTypes.GetAdminStatistical: {
        // @ts-ignore
        return {...state, ...{ pageAdminStatistical: {},
          // @ts-ignore
          searchParams: action.payload?.searchParams,
          [`isLoading_${actionTypes.GetAdminStatistical}`]: true}};
      }
      case actionTypes.GetAdminStatisticalLoaded: {
        // @ts-ignore
        const pageAdminStatistical = action.payload?.result as AdminStatisticalType;
        // @ts-ignore
        return {...state, ...{ pageAdminStatistical, [`isLoading_${actionTypes.GetAdminStatistical}`]: false}};
      }
      case actionTypes.ResetAdminStatisticalInfo: {
        return {...state, pageAdminStatistical: {}};
      }
      case actionTypes.DownloadAdminStatistical: {
        // @ts-ignore
        return {...state, ...{
          // @ts-ignore
          searchParams: action.payload?.searchParams,
          [`isLoading_${actionTypes.DownloadAdminStatistical}`]: true}};
      }
      case actionTypes.DownloadAdminStatisticalLoaded: {
        // @ts-ignore
        return {...state, ...{
          [`isLoading_${actionTypes.DownloadAdminStatistical}`]: false}};
      }
      case actionTypes.UpdateUserInfo: {
        // @ts-ignore
        return {...state, ...{userUpdateInfo: action.payload?.userUpdateInfo, [`isLoading_${actionTypes.UpdateUserInfo}`]: true}};
      }
      case actionTypes.UpdatedUserInfoLoaded: {
        // @ts-ignore
        const userUpdated = action.payload?.userUpdatedInfo;
        // @ts-ignore
        const lstUsers = _.get(state, 'pageAdminUserInfo.lstUsers', []);
        lstUsers.map((u : UserRentMailModel) => {
          if (u.key === userUpdated.key) {
            u.balance = userUpdated.balance;
          }
          return u;
        });
        const lstUserPhones = _.get(state, 'pageAdminUserPhoneInfo.lstUserPhones', []);
        // @ts-ignore
        lstUserPhones.map((u: AdminUserPhoneType) => {
          if (u.key === userUpdated.key) {
            u.numWait = userUpdated.numWait;
            u.maxNumWait = userUpdated.maxNumWait;
            u.otpLength = userUpdated.otpLength;
          }
          return u;
        });
        // @ts-ignore
        return {...state, ...{[`isLoading_${actionTypes.UpdateUserInfo}`]: false}};
      }
      case actionTypes.DownloadEmailsTransaction: {
        // @ts-ignore
        return {...state, ...{ transactionType: action.payload?.transactionType, transactionUid: action.payload?.transaction,
          [`isLoading_${actionTypes.GetMails}`]: true }};
      }
      case actionTypes.DownloadEmailsTransactionCompleted: {
        return {...state, ...{ [`isLoading_${actionTypes.GetMails}`]: false }};
      }
      case actionTypes.CopyBuyEmails: {
        return {...state, transactionUid: action.payload?.transaction, isCopyingBuyEmails: true};
      }
      case actionTypes.CopyBuyEmailsCompleted: {
        return {...state, isCopyingBuyEmails: false};
      }
      case actionTypesAuth.Logout: {
        return initialServicesState;
      }
      case actionTypes.GetConfig: {
        // @ts-ignore
        return {...state, [`isLoading_${actionTypes.GetConfig}`]: true };
      }
      case actionTypes.GetConfigLoaded: {
        // @ts-ignore
        const config = action.payload?.config || {};
        return {...state, config, [`isLoading_${actionTypes.GetConfig}`]: false };
      }
      case actionTypes.UpdateConfig: {
        // @ts-ignore
        const configUpdateInfo = action.payload?.configUpdateInfo || {};
        return {...state, configUpdateInfo, [`isLoading_${actionTypes.UpdateConfig}`]: true};
      }
      case actionTypes.UpdateConfigCompleted: {
        return {...state, [`isLoading_${actionTypes.UpdateConfig}`]: false};
      }
      case actionTypes.ResetAllThread: {
        return {...state, [`isLoading_${actionTypes.ResetAllThread}`]: true };
      }
      case actionTypes.ResetAllThreadDone: {
        const lstUserPhones = _.get(state, 'pageAdminUserPhoneInfo.lstUserPhones', []);
        // @ts-ignore
        lstUserPhones.map((u: { _id: string, numWait: number, maxNumWait: number }) => {
          u.numWait = 0;
          return u;
        });
        return {...state, [`isLoading_${actionTypes.ResetAllThread}`]: false };
      }
      case actionTypes.ReleaseWaitPhone: {
        return {...state, [`isLoading_${actionTypes.ReleaseWaitPhone}`]: true };
      }
      case actionTypes.ReleaseWaitPhoneDone: {
        return {...state, [`isLoading_${actionTypes.ReleaseWaitPhone}`]: false };
      }
      case actionTypes.UpdateService: {
        // @ts-ignore
        return {...state, updateServiceInfo: action.payload?.updateServiceInfo, [`isLoading_${actionTypes.UpdateService}`]: true};
      }
      case actionTypes.UpdateServiceCompleted: {
        return {...state, [`isLoading_${actionTypes.UpdateService}`]: false};
      }
      case actionTypes.ImportFacebookAccount: {
        // @ts-ignore
        return {...state, facebookAccountsParam: action.payload.facebookAccountsParam, [`isLoading_${actionTypes.ImportFacebookAccount}`]: true};
      }
      case actionTypes.ImportFacebookAccountCompleted: {
        // @ts-ignore
        return {...state, importFacebookRsl: action.payload.importFacebookRsl, [`isLoading_${actionTypes.ImportFacebookAccount}`]: false};
      }
      case actionTypes.IsImportingFacebookAccount: {
        // @ts-ignore
        const isImporting = action.payload?.isImporting;
        return {...state, [`isLoading_${actionTypes.IsImportingFacebookAccount}`]: isImporting}
      }
      case actionTypes.InsertApiLinkWebsiteConfig: {
        // @ts-ignore
        const apiLinkWebsiteConfig = action.payload?.apiLinkWebsiteConfig;
        return {...state, apiLinkWebsiteConfig, [`isLoading_${actionTypes.InsertApiLinkWebsiteConfig}`]: true}
      }
      case actionTypes.UpdateApiLinkWebsiteConfig: {
        // @ts-ignore
        const apiLinkWebsiteConfig = action.payload?.apiLinkWebsiteConfig;
        return {...state, apiLinkWebsiteConfig, [`isLoading_${actionTypes.InsertApiLinkWebsiteConfig}`]: true}
      }
      case actionTypes.UpsertApiLinkWebsiteConfigComplete: {
        return {
          ...state,
          [`isLoading_${actionTypes.InsertApiLinkWebsiteConfig}`]: false,
          isUpsertApiLinkWebsiteConfigSuccess: true
        }
      }
      case actionTypes.GetApiLinkWebsiteConfigs: {
        return {...state, [`isLoading_${actionTypes.GetApiLinkWebsiteConfigs}`]: true}
      }
      case actionTypes.ApiLinkWebsiteConfigsLoaded: {
        return {
          ...state,
          [`isLoading_${actionTypes.GetApiLinkWebsiteConfigs}`]: false,
          apiLinkWebsiteConfigs: action.payload?.apiLinkWebsiteConfigs as ApiLinkWebsiteConfigType[]
        }
      }
      case actionTypes.SetUpsertApiLinkWebsiteConfigComplete: {
        return {
          ...state,
          isUpsertApiLinkWebsiteConfigSuccess: action.payload?.isUpsertApiLinkWebsiteConfigSuccess as boolean
        }
      }
      case actionTypes.DeleteApiLinkWebsiteConfig: {
        return {
          ...state,
          // @ts-ignore
          apiLinkWebsiteId: action.payload?.apiLinkWebsiteId,
          [`isLoading_${actionTypes.InsertApiLinkWebsiteConfig}`]: false,
        };
      }
      case actionTypes.GetServicesQuantity: {
        return {
          ...state,
          [`isLoading_${actionTypes.GetServicesQuantity}`]: true,
        }
      }
      case actionTypes.GetServicesQuantityLoaded: {
        return {
          ...state,
          servicesQuantity: action.payload?.servicesQuantity,
          [`isLoading_${actionTypes.GetServicesQuantity}`]: false,
        }
      }
      default:
        return {...state, ...{services: state?.services,
          rentMailsCreated: state?.rentMailsCreated,
          rentMailsHistory: state?.rentMailsHistory,
          transaction: state?.transaction}}
    }
  }
);

export function* saga() {
  yield takeLatest(actionTypes.GetServices, function* getServiceSaga() {
    yield put(actionsRentMails.fetchServices())
  });

  yield takeLatest(actionTypes.FetchServices, function* fetchServiceSaga() {
    // @ts-ignore
    const getServiceType = (state) => state.rentMail.serviceType;
    const serviceType: string | string[] = yield select(getServiceType);
    // @ts-ignore
    const {data} = yield getServices(serviceType);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillServices(data.services));
  });

  yield takeLatest(actionTypes.GetServicesRealtime, function* getServicesRealtimeSaga() {
    // @ts-ignore
    const getServiceKeys = (state) => state.rentMail.serviceKeys;
    const serviceKeys: string[] = yield select(getServiceKeys);
    // @ts-ignore
    const {data} = yield getServicesRealtime(serviceKeys);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.getServicesRealtimeLoaded(_.get(data, 'services', [])));
  });

  yield takeLatest(actionTypes.CreateRentMail, function* createRentMailSaga() {
    // @ts-ignore
    const getServiceId = (state) => state.rentMail.serviceIdCreated;
    const serviceId: string = yield select(getServiceId);
    // @ts-ignore
    const {data} = yield createRentMail(serviceId);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillCreatedRentMail('phone', !_.isEmpty(data.email) ? [data.email] : []));
    yield put(actions.requestUserRentMail());
  });

  yield takeLatest(actionTypes.CreateReRentMail, function* createReRentMailSaga() {
    // @ts-ignore
    const getServiceId = (state) => state.rentMail.serviceIdCreated;
    const serviceId: string = yield select(getServiceId);
    // @ts-ignore
    const getPhone = (state) => state.rentMail.phoneCreated;
    const phone: string = yield select(getPhone);
    // @ts-ignore
    const getPhoneId = (state) => state.rentMail.phoneIdCreated;
    const phoneId: string = yield select(getPhoneId);
    // @ts-ignore
    const {data} = yield createReRentMail(phoneId, serviceId, phone);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillCreatedRentMail('phone', !_.isEmpty(data.email) ? [data.email] : []));
    yield put(actions.requestUserRentMail());
  });

  yield takeLatest(actionTypes.CreateSellMail, function* createSellMailSaga() {
    // @ts-ignore
    const rentMailState = (state) => state.rentMail;
    // @ts-ignore
    const { serviceIdCreated, numEmailCreated, serviceTypeCreated, requestMailTypeCreated } = yield select(rentMailState);
    const delay = (delayInms: number) => {
      return new Promise(resolve => setTimeout(resolve, delayInms));
    }
    let data = {};
    if (numEmailCreated < 100) {
      // @ts-ignore
      const result = yield createSellMail(serviceTypeCreated, serviceIdCreated, numEmailCreated, requestMailTypeCreated);
      data = result.data;
      if (_.get(data, 'code') === 401) {
        yield put(actions.logout());
        return;
      }
      yield put(actionsRentMails.setPercentageSellMail(100));
      yield delay(1000);
    } else {
      const QUANTITY = 100;
      let numGetMail = Math.floor(numEmailCreated/QUANTITY);
      if (numEmailCreated%QUANTITY !== 0) {
        numGetMail += 1;
      }
      // @ts-ignore
      let totalAcc = [];
      let transactionId = '';
      for (let i = 0; i < numGetMail; i++) {
        const numMail = ((i === numGetMail-1) && (numEmailCreated%QUANTITY !== 0)) ? numEmailCreated%QUANTITY : QUANTITY;
        const totalFinished: number = totalAcc.length;
        // @ts-ignore
        const result = yield createSellMail(serviceTypeCreated, serviceIdCreated, numMail, requestMailTypeCreated, transactionId, totalFinished);
        const dataGet = result.data;
        if (_.get(dataGet, 'code') === 401) {
          yield put(actions.logout());
          return;
        }
        transactionId = _.get(dataGet, 'transactionId', '');
        if (serviceTypeCreated === 'mail') {
          // @ts-ignore
          totalAcc = totalAcc.concat(_.get(dataGet, 'emails', []));
        } else if (['facebook', 'textnow'].includes(serviceTypeCreated)) {
          // @ts-ignore
          totalAcc = totalAcc.concat(_.get(dataGet, 'accounts', []));
        }
        yield put(actionsRentMails.setPercentageSellMail(Math.floor((i+1)/numGetMail*100)));
        yield delay(1000);
      }
      if (serviceTypeCreated === 'mail') {
        Object.assign(data, {emails: totalAcc});
      } else if (['facebook', 'textnow'].includes(serviceTypeCreated)) {
        Object.assign(data, {accounts: totalAcc});
      }
    }
    if (serviceTypeCreated === 'mail') {
      // this is for mail type
      yield put(actionsRentMails.fulfillCreatedRentMail('mail', _.get(data, 'emails', [])));
    } else if (['facebook', 'textnow'].includes(serviceTypeCreated)) {
      // this is for account type
      yield put(actionsRentMails.fulfillCreatedRentMail('account', _.get(data, 'accounts', [])));
    }
    if ((serviceTypeCreated === 'mail' && _.isEmpty(_.get(data, 'emails', []))) || (['facebook', 'textnow'].includes(serviceTypeCreated) && _.isEmpty(_.get(data, 'accounts', [])))) {
      return;
    }
    yield put(actionsRentMails.getTrans({
      transactionType: ['mail', 'textnow'],
      fromDate: toIsoString(new Date(moment().format('YYYY/MM/DD'))),
      toDate: toIsoString(new Date(moment().format('YYYY/MM/DD'))),
      page: 1,
      limit: 10}));
    yield put(actions.requestUserRentMail());
    yield put(actionsRentMails.getServices(['mail', 'textnow']));
  });

  yield takeLatest(actionTypes.GetMails, function* getMailSaga() {
    // @ts-ignore
    const getSearchMailParams = (state) => state.rentMail.searchMailParams;
    const searchMailParams: SearchMailParamsType
      = yield select(getSearchMailParams);
    const {data} = yield getMails(searchMailParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetRentMails(_.get(data, 'result', {})));
    yield put(actions.requestUserRentMail());
  });

  yield takeLatest(actionTypes.GetBuyMails, function* getMailSaga() {
    // @ts-ignore
    const getSearchMailParams = (state) => state.rentMail.searchMailParams;
    const searchMailParams: SearchMailParamsType
      = yield select(getSearchMailParams);
    const {data} = yield getBuyMails(searchMailParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetRentMails(_.get(data, 'result', {})));
    yield put(actions.requestUserRentMail());
  });

  yield takeLatest(actionTypes.GetMailsHistory, function* getMailHistorySaga() {
    // @ts-ignore
    const getSearchMailParams = (state) => state.rentMail.searchMailParams;
    const searchMailParams: SearchMailParamsType
      = yield select(getSearchMailParams);
    const {data} = yield getMails(searchMailParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetMailsHistory(_.get(data, 'result', {})));
  });

  yield takeLatest(actionTypes.GetBuyMailsHistory, function* getBuyMailHistorySaga() {
    // @ts-ignore
    const getSearchMailParams = (state) => state.rentMail.searchMailParams;
    const searchMailParams: SearchMailParamsType
      = yield select(getSearchMailParams);
    const {data} = yield getBuyMails(searchMailParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetMailsHistory(_.get(data, 'result', {})));
  });

  yield takeLatest(actionTypes.GetTrans, function* getTransSaga() {
    // @ts-ignore
    const getSearchsTranParams = (state) => state.rentMail.searchTransParams;
    const searchTransParam: SearchTransParamsType
      = yield select(getSearchsTranParams);
    const {data} = yield getTransaction(searchTransParam);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetTrans(_.get(data, 'result', {})));
  });

  yield takeLatest(actionTypes.GetMailsRealtime, function* getMailsRealtimeSaga() {
    // @ts-ignore
    const getGetMailsRealtimeParams = (state) => state.rentMail.searchMailsRealtimeParams;
    const searchMailsRealtimeParams: SearchMailsRealtimeParamsType = yield select(getGetMailsRealtimeParams);
    const {data} = yield getMailsRealtime(searchMailsRealtimeParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetMailsRealtime(_.get(data, 'result', {})));
    yield put(actions.requestUserRentMail());
  });
    
  yield takeLatest(actionTypes.GetAdminUserInfo, function* getUsersSaga() {
    // @ts-ignore
    const getSearchPageAdminInfoParams = (state) => state.rentMail.searchPageAdminInfoParams;
    const searchPageAdminInfoParams: SearchPageAdminInfoParamsType = yield select(getSearchPageAdminInfoParams);
    // @ts-ignore
    const {data} = yield getAdminUserInfo(searchPageAdminInfoParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetAdminUserInfo(_.get(data, 'result', {})));
  });

  yield takeLatest(actionTypes.GetAdminUserPhoneInfo, function* getUserPhonesSaga() {
    // @ts-ignore
    const getSearchPageAdminUserPhoneInfoParams = (state) => state.rentMail.searchPageAdminUserPhoneInfoParams;
    const searchPageAdminInfoParams: SearchPageAdminInfoParamsType = yield select(getSearchPageAdminUserPhoneInfoParams);
    // @ts-ignore
    const {data} = yield getAdminPhoneInfo(searchPageAdminInfoParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetAdminUserPhoneInfo(_.get(data, 'result', {})));
  });

  yield takeLatest(actionTypes.GetAdminUserMailInfo, function* getUserMailsSaga() {
    // @ts-ignore
    const getSearchPageAdminUserPhoneInfoParams = (state) => state.rentMail.searchPageAdminUserPhoneInfoParams;
    const searchPageAdminInfoParams: SearchPageAdminInfoParamsType = yield select(getSearchPageAdminUserPhoneInfoParams);
    // @ts-ignore
    const {data} = yield getAdminMailInfo(searchPageAdminInfoParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetAdminUserMailInfo(_.get(data, 'result', {})));
  });

  yield takeLatest(actionTypes.GetAdminUserFbInfo, function* getUserFbsSaga() {
    // @ts-ignore
    const getSearchPageAdminUserPhoneInfoParams = (state) => state.rentMail.searchPageAdminUserPhoneInfoParams;
    const searchPageAdminInfoParams: SearchPageAdminInfoParamsType = yield select(getSearchPageAdminUserPhoneInfoParams);
    // @ts-ignore
    const {data} = yield getAdminFbInfo(searchPageAdminInfoParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetAdminUserFbInfo(_.get(data, 'result', {})));
  });

  yield takeLatest(actionTypes.GetAdminUserTxInfo, function* getUserTxsSaga() {
    // @ts-ignore
    const getSearchPageAdminUserPhoneInfoParams = (state) => state.rentMail.searchPageAdminUserPhoneInfoParams;
    const searchPageAdminInfoParams: SearchPageAdminInfoParamsType = yield select(getSearchPageAdminUserPhoneInfoParams);
    // @ts-ignore
    const {data} = yield getAdminTxInfo(searchPageAdminInfoParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetAdminUserTxInfo(_.get(data, 'result', {})));
  });

  yield takeLatest(actionTypes.GetAdminStatistical, function* getAdminStatisticalSaga() {
    // @ts-ignore
    const getSearchParams = (state) => state.rentMail.searchParams;
    const searchParams: {fromDate: string, toDate: string} = yield select(getSearchParams);
     // @ts-ignore
    const {data} = yield getAdminStatistical(searchParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillGetAdminStatistical(_.get(data, 'result', {})));
  });

  yield takeLatest(actionTypes.DownloadAdminStatistical, function* downloadAdminStatisticalSaga() {
    // @ts-ignore
    const getSearchParams = (state) => state.rentMail.searchParams;
    const searchParams: {fromDate: string, toDate: string} = yield select(getSearchParams);
     // @ts-ignore
    const {data} = yield downloadAdminStatistical(searchParams);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `REPORT_WINMAIL_${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    yield put(actionsRentMails.fulfillDownloadAdminStatistical());
  });

  yield takeLatest(actionTypes.UpdateUserInfo, function* updateUserInfoSaga() {
    // @ts-ignore
    const getUserUpdateInfo = (state) => state.rentMail.userUpdateInfo;
    const userUpdateInfo: {coin?: number, password?: string, disabled?: boolean} = yield select(getUserUpdateInfo);
    const {data} = yield updateUserInfo(userUpdateInfo);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillUserUpdatedByAdmin(_.get(data, 'user')));
  });

  yield takeLatest(actionTypes.DownloadEmailsTransaction, function* downloadEmailsTransaction() {
    // @ts-ignore
    const getTransactionUid = (state) => state.rentMail.transactionUid;
    const transactionUid: string = yield select(getTransactionUid);
    // @ts-ignore
    const getTransactionType = (state) => state.rentMail.transactionType;
    const transactionType: string = yield select(getTransactionType);
    const {data} = yield getBuyMails({
      transactionType,
      transaction: transactionUid,
    });
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    let content = [];
    if (transactionType === 'mail') {
      const emails = _.get(data, 'result.emails', []);
      content = emails.map((e: MailModel) => { 
        const newEmail = { email: e.email};
        if (_.get(e, 'mailRecovery')) {
          Object.assign(newEmail, {emailRecovery: e.mailRecovery});
        }
        Object.assign(newEmail, {password: e.password});
        return newEmail;
      }).map((e: MailModel) => Object.values(e).join("|")).join("\n");
    } else if (transactionType === 'facebook') {
      const accounts = _.get(data, 'result.accounts', []);
      content = accounts.map((ac: AccountFacebookModel) => {
        const account = {
          uid: ac.uid,
          password: ac.password,
          '2fa': ac['2fa'],
          cookie: ac.cookie,
          token: ac.token,
          useragent: ac.useragent,
          mail: ac.mail,
          passmail: ac.passmail,
        };
        return account;
      }).map((ac: AccountFacebookModel) => Object.values(ac).join("|").replace('||', '|')).join("\n");
    } else if (transactionType === 'textnow') {
      const accounts = _.get(data, 'result.accounts', []);
      content = accounts.map((ac: AccountTextnowModel) => {
        const account = {
          email: ac.email,
          password: ac.password,
          phone: ac.phone,
        };
        return account;
      }).map((ac: AccountFacebookModel) => Object.values(ac).join("|").replace('||', '|')).join("\n");
    }
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', `${transactionUid}.txt`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    yield put(actionsRentMails.DownloadEmailsTransactionCompleted());
  });

  yield takeLatest(actionTypes.CopyBuyEmails, function* copyBuyEmails() {
    // @ts-ignore
    const getTransactionUid = (state) => state.rentMail.transactionUid;
    const transactionUid: string = yield select(getTransactionUid);
    const {data} = yield getBuyMails({
      transactionType: 'mail',
      transaction: transactionUid,
    });
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    const emails = _.get(data, 'result.emails', []);
    const content = emails.map((e: MailModel) => { 
      const newEmail = { email: e.email, password: e.password };
      return newEmail;
    }).map((e: MailModel) => Object.values(e).join("|")).join("\n");
    clipboard.write(content);
    yield put(actionsRentMails.CopyBuyEmailsCompleted());
  });

  yield takeLatest(actionTypes.GetConfig, function* getConfigSaga() {
    const {data} = yield getConfig();
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    const config: AdminConfigType = _.get(data, 'config', {})
    yield put(actionsRentMails.fulfillConfig(config));
  });

  yield takeLatest(actionTypes.UpdateConfig, function* updateConfigSaga() {
    // @ts-ignore
    const getConfigUpdateInfo = (state) => state.rentMail.configUpdateInfo;
    const configUpdateInfo: AdminConfigType = yield select(getConfigUpdateInfo);
    const {data} = yield updateConfig(configUpdateInfo);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.updateConfigCompleted());
    yield put(actionsRentMails.getConfig());
  });

  yield takeLatest(actionTypes.ResetAllThread, function* resetAllThreadSaga() {
    const {data} = yield resetAllThread();
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.resetAllThreadDone());
  });

  yield takeLatest(actionTypes.ReleaseWaitPhone, function* releaseWaitPhoneSaga() {
    const {data} = yield releaseWaitTextNow();
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.releaseWaitPhoneDone());
  });

  yield takeLatest(actionTypes.UpdateService, function* updateServiceSaga() {
    // @ts-ignore
    const getUpdateServiceInfo = (state) => state.rentMail.updateServiceInfo;
    const updateServiceInfo: {service: string, disabled?: boolean, isLinkApi?: boolean} = yield select(getUpdateServiceInfo);
    const {data} = yield updateService(updateServiceInfo);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.updateServiceCompleted());
    yield put(actionsRentMails.getServices(['phone', 'mail', 'facebook']));
  });

  yield takeLatest(actionTypes.ImportFacebookAccount, function* () {
    // @ts-ignore
    const getFacebookAccountsParam = (state) => state.rentMail.facebookAccountsParam;
    const accounts: AccountFacebookModel[] = yield select(getFacebookAccountsParam);
    // @ts-ignore
    const {data} = yield importFacebookAccount(accounts, 'only_import');
    yield put(actionsRentMails.importFacebookAccountCompleted(_.get(data, 'result', {completed: 0, error: 0})));
  });

  yield takeLatest(actionTypes.InsertApiLinkWebsiteConfig, function* insertApiLinkWebsiteConfigSaga() {
    // @ts-ignore
    const getApiLinkWebsiteConfig = (state) => state.rentMail.apiLinkWebsiteConfig;
    const apiLinkWebsiteConfig: ApiLinkWebsiteConfigType = yield select(getApiLinkWebsiteConfig);
    const {data} = yield insertApiLinkWebsite(apiLinkWebsiteConfig);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.upsertApiLinkWebsiteConfigComplete());
    yield put(actionsRentMails.getApiLinkWebsiteConfigs());
  });

  yield takeLatest(actionTypes.UpdateApiLinkWebsiteConfig, function* updateApiLinkWebsiteConfigSaga() {
    // @ts-ignore
    const getApiLinkWebsiteConfig = (state) => state.rentMail.apiLinkWebsiteConfig;
    // @ts-ignore
    const apiLinkWebsiteConfig: any = yield select(getApiLinkWebsiteConfig);
    const {data} = yield updateApiLinkWebsite(apiLinkWebsiteConfig);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.upsertApiLinkWebsiteConfigComplete());
    yield put(actionsRentMails.getApiLinkWebsiteConfigs());
  });

  yield takeLatest(actionTypes.GetApiLinkWebsiteConfigs, function* getApiLinkWebsiteConfigsSaga() {
    // @ts-ignore
    const {data} = yield getApiLinkWebsiteConfig();
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillApiLinkWebsiteConfigs(_.get(data, 'result.apiLinkWebsites', [])));
  });

  yield takeLatest(actionTypes.DeleteApiLinkWebsiteConfig, function* deleteApiLinkWebsiteConfigSaga() {
    // @ts-ignore
    const rentMailState = (state) => state.rentMail;
    // @ts-ignore
    const { apiLinkWebsiteId } = yield select(rentMailState);
    const {data} = yield deleteApiLinkWebsite(apiLinkWebsiteId);
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.upsertApiLinkWebsiteConfigComplete());
    yield put(actionsRentMails.getApiLinkWebsiteConfigs());
  });

  yield takeLatest(actionTypes.GetServicesQuantity, function* getServicesQuantitySaga() {
    // @ts-ignore
    const {data} = yield getServicesQuantity();
    if (data.code === 401) {
      yield put(actions.logout());
      return;
    }
    yield put(actionsRentMails.fulfillServicesQuantity(_.get(data, 'services', {})));
  });
}
