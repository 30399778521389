import moment from "moment";
import {
  refreshIdToken,
} from "../../../src/app/modules/rent-mails/firebase";
import {actions} from "../../app/modules/auth/redux/AuthRedux";
import jwtDecode from "jwt-decode";
import _ from "lodash";

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    async (config: any) => {
      const {
        auth: {idToken},
        auth,
      } = store.getState();
      let idTokenTmp = idToken;
      if (idTokenTmp) {
        const tokenDecode = jwtDecode(idTokenTmp);
        const expiredTime = _.get(tokenDecode, 'exp');
        if (!expiredTime) {
          store.dispatch(actions.logout());
          return config;
        }
        const now = new Date();
        now.setHours(now.getHours() - 1);
        if (expiredTime < now.getTime()/1000) {
          store.dispatch(actions.logout());
          return config;
        } else if (expiredTime < new Date().getTime()/1000) {
          idTokenTmp = await refreshIdToken();
          if (!idTokenTmp) {
            store.dispatch(actions.logout());
            return config;
          }
          store.dispatch(actions.updateIdToken(idTokenTmp));
        }
        if (idTokenTmp) {
          config.headers.Authorization = `Bearer ${idTokenTmp}`
        }
      } else {
        store.dispatch(actions.logout());
      }
      return config;
    },
    (err: any) => Promise.reject(err)
  )
}
