/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {Languages} from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import { RootState } from '../../../../setup'
import { UserRentMailModel } from '../../../../app/modules/rent-mails/models/UserRentMailModel'
import {
  logout
} from "../../../../app/modules/rent-mails/firebase";
import _ from 'lodash'
import { KTSVG } from '../../../helpers'


const RentMailHeaderUserMenu: FC = () => {
  const user: UserModel =
    useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
  const userRentMail: UserRentMailModel =
    useSelector<RootState>(({auth}) => auth.userRentMail, shallowEqual) as UserRentMailModel;

  const dispatch = useDispatch();
  const signOut = () => {
    logout(() => dispatch(auth.actions.logout()));
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='WinMail' src={_.get(user, 'picture', 'https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/User.svg')} />
          </div>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {_.get(user, 'name', _.get(userRentMail, 'name'))}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {_.get(user, 'email', '')}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <span className='text-primary px-5'>Coin: {_.get(userRentMail, 'balance', 0).toLocaleString("en-US")}</span>
      </div>

      {/* <Languages /> TODO*/}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> TODO*/}

      <div className='menu-item px-5'>
        <a onClick={signOut} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {RentMailHeaderUserMenu}
