import {FC, useRef, useEffect} from 'react'
import {connect, useDispatch, ConnectedProps} from 'react-redux'
import * as rentMail from './RentMailRedux'
import {RootState} from '../../../../setup'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import { toIsoString } from '../common/common'

const mapState = (state: RootState) => ({rentMail: state.rentMail})
const connector = connect(mapState, rentMail.actionsRentMails)
type PropsFromRedux = ConnectedProps<typeof connector>

const TransactionSellTxInit: FC<PropsFromRedux> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const requestTrans = async () => {
      if (!didRequest.current) {
        dispatch(props.getServices('textnow'));
        dispatch(props.getTrans({
          transactionType: 'textnow',
          fromDate: toIsoString(new Date(moment().add(-30, 'days').format('YYYY/MM/DD'))),
          toDate: toIsoString(new Date(moment().format('YYYY/MM/DD'))),
          emailUser: searchParams.get('userEmail') as string,
          page: 1,
          limit: 10}));
      }
      return () => (didRequest.current = true)
    };
    requestTrans();
  }, [])

  return <>{props.children}</>
}

export default connector(TransactionSellTxInit)
