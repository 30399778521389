import {FC, useRef, useEffect} from 'react'
import {connect, useDispatch, ConnectedProps} from 'react-redux'
import * as rentMail from './RentMailRedux'
import {RootState} from '../../../../setup'
import moment from 'moment'
import { toIsoString } from '../common/common'

const mapState = (state: RootState) => ({rentMail: state.rentMail})
const connector = connect(mapState, rentMail.actionsRentMails)
type PropsFromRedux = ConnectedProps<typeof connector>

const SellMailInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const requestInit = async () => {
      if (!didRequest.current) {
        dispatch(props.getTrans({
          transactionType: ['mail', 'facebook', 'textnow'],
          type: 'charge',
          fromDate: toIsoString(new Date(moment().format('YYYY/MM/DD'))),
          toDate: toIsoString(new Date(moment().format('YYYY/MM/DD'))),
          page: 1,
          limit: 10}));
      }
      return () => (didRequest.current = true)
    };
    requestInit();
  }, [])

  return <>{props.children}</>
}

export default connector(SellMailInit)
