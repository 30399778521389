/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { ServiceModel } from './models/ServiceModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { Loading } from './components/Loading'
import { CommonFunc } from './common/common'
import { actionTypes } from './redux/RentMailRedux'
import _ from 'lodash'
import { TransactionModel } from './models/TransactionModel'
import HistorySellTxInit from './redux/HistorySellTxInit'
import { TxHistoryTable } from './components/TxHistoryTable'

const TxHistoryWrapper: FC = () => {
  const services: ServiceModel[] =
    useSelector<RootState>(({rentMail}) => _.get(rentMail, 'services', []), shallowEqual) as ServiceModel[];
  const transaction: {lstTrans: TransactionModel[], total: number} =
    useSelector<RootState>(({rentMail}) => rentMail.transaction, shallowEqual) as {lstTrans: TransactionModel[], total: number};
  const isLoading: boolean =
    useSelector<RootState>(({rentMail}) => CommonFunc.isLoading(rentMail, actionTypes), shallowEqual) as boolean;
  return (
    <>
      <Loading isLoading={isLoading} />
      <PageTitle breadcrumbs={[]}>Lịch sử mua acc textnow</PageTitle>
      <div className='row g-5 gx-xxl-8 spinner spinner-primary mr-15'>
        <div className='col-xxl-12'>
          <HistorySellTxInit>
            <TxHistoryTable
              dataChart={_.get(transaction, 'dataChart', [])}
              transactions={_.get(transaction, 'lstTrans', []).slice(0, 10)}
              totalRows={_.get(transaction, 'total', 0)}
              services={services}
              className='card-xxl-stretch mb-5 mb-xxl-8' />
          </HistorySellTxInit>
        </div>
      </div>
    </>
  )
}

export default TxHistoryWrapper;
