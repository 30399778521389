/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, actions} from '../modules/auth'
import {RootState} from '../../setup'
import {App} from '../App'
import { Fab, Action } from 'react-tiny-fab';
import clsx from 'clsx'
import { KTSVG } from '../../_metronic/helpers'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
    const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual);
    const dispatch = useDispatch();
    useEffect(() => {
        if (isAuthorized) {
            dispatch(actions.requestUserRentMail());
            // dispatch(actionsRentMails.refreshTransactionCreatedSuccess());
        }
    }, [isAuthorized]);
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>
                    <Route path='error/*' element={<ErrorsPage />} />
                    <Route path='logout' element={<Logout />} />
                    {isAuthorized ? (
                        <>
                            <Route path='/*' element={<PrivateRoutes />} />
                            <Route index element={<Navigate to='/main' />} />
                        </>
                    ) : (
                        <>
                            <Route path='auth/*' element={<AuthPage />} />
                            <Route path='*' element={<Navigate to='/auth' />} />
                        </>
                    )}
                </Route>
            </Routes>
            <Fab
                mainButtonStyles={{width: '0px', height: '0px'}}
                icon={<a
                  data-kt-menu-trigger='click'
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='bottom'
                  target="_blank"
                >
                    <img
                      style={{width: '56px', height: '56px'}}
                      src='/media/icons/duotune/communication/icons-chat-64.png'
                    />
                </a>}
                alwaysShowTitle={true}
            >
              <Action
                style={{height: '0px'}}
              >
                <a
                  href='http://m.me/winmail38'
                  target="_blank"
                >
                  <KTSVG
                      path='/media/icons/duotune/social/facebook-messenger.svg'
                  />
                </a>
              </Action>
              <Action
                style={{height: '0px'}}
              >
                <a
                  href='https://zalo.me/g/pffmak872'
                  target="_blank"
                >
                  <KTSVG
                      path='/media/icons/duotune/social/zalo.svg'
                  />
                </a>
              </Action>
              <Action
                style={{height: '0px'}}
              >
                <a
                  href='https://telegram.me/Winmailshop38'
                  target="_blank"
                >
                  <KTSVG
                      path='/media/icons/duotune/social/telegram.svg'
                  />
                </a>
              </Action>
            </Fab>
        </BrowserRouter>
    )
}

export {AppRoutes}
