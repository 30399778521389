/*jslint es6 */
/*jslint devel: true, browser: true, white: true */
import firebase from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA8MZ6oZ5guGUrxiGAer1IthVVlVXxNswA",
  authDomain: "rentmails-e43fa.firebaseapp.com",
  projectId: "rentmails-e43fa",
  storageBucket: "rentmails-e43fa.appspot.com",
  messagingSenderId: "1061553022732",
  appId: "1:1061553022732:web:18ecfa1ae1cef1292ccb29"
};
const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth(app);
const db = firebase.firestore(app);

const googleProvider = new firebase.auth.GoogleAuthProvider();
const signInWithGoogle = async (getUserCallback) => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    const docs = (await db.collection("users").where("uid", "==", user.uid).get()).docs;
    if (docs.length === 0) {
      await db.collection("users").doc(user.uid).set({name: user.displayName,
        email: user.email, uid: user.uid});
    }
    const idToken = await user.getIdToken();
    getUserCallback(res.credential.accessToken, idToken);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithEmailAndPassword = async (email, password, getUserCallback, errorCallback) => {
  try {
    const res = await auth.signInWithEmailAndPassword(email, password);
    const user = res.user;
    const docs = (await db.collection("users").where("uid", "==", user.uid).get()).docs;
    if (docs.length === 0) {
      throw Error("User is not exist");
    }
    const idToken = await user.getIdToken();
    getUserCallback({uid: user.uid, email: user.email}, idToken);
  } catch (err) {
    console.error(err);
    switch(err.code) {
      case 'auth/wrong-password':
        errorCallback("Mật khẩu sai hoặc user này không có mật khẩu.");
        break;
      case 'auth/user-not-found':
        errorCallback("Tài khoản này không tồn tại.");
        break;
      default:
        errorCallback(err.message);
        break;
    }
  }
};

const createUserWithEmailAndPassword = async (firstName, lastName, email, password, getUserCallback, errorCallback) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;
    const docs = (await db.collection("users").where("uid", "==", user.uid).get()).docs;
    if (docs.length === 0) {
      await db.collection("users").doc(user.uid).set({name: firstName + " " + lastName,
        email: user.email, uid: user.uid});
      const idToken = await user.getIdToken();
      getUserCallback({uid: user.uid, name: firstName + " " + lastName, email: user.email}, idToken);
    }
  } catch (err) {
    console.error(err);
    switch(err.code) {
      case 'auth/email-already-in-use':
        errorCallback("Tài khoản này đã được sử dụng bởi người khác.");
        break;
      default:
        errorCallback(err.message);
        break;
    }
  }
};

const logout = (signOutCallback) => {
  firebase.auth().signOut().then(() => signOutCallback());
};

const refreshIdToken = async () =>{
  if (auth.currentUser) {
    const idToken = await auth.currentUser.getIdToken(true);
    return idToken;
  }
}

export {
  signInWithGoogle,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  logout,
  refreshIdToken
};