import axios from 'axios'
import _ from 'lodash'
import { AccountFacebookModel } from '../models/AccountFacebookModel'
import { MailModel } from '../models/MailModel'
import { ServiceModel } from '../models/ServiceModel'
import { TransactionModel } from '../models/TransactionModel'
import { UserRentMailModel } from '../models/UserRentMailModel'
import { AccountTextnowModel } from '../models/AccountTextnowModel'

const API_URL = process.env.REACT_APP_API_URL
const RENT_MAIL_API_URL = process.env.REACT_APP_RENT_MAIL_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const GET_SERVICES_URL = `${RENT_MAIL_API_URL}/services/all`
export const GET_SERVICES_REALTIME_URL = `${RENT_MAIL_API_URL}/services/realtime`
export const CREATE_EMAIL_URL = `${RENT_MAIL_API_URL}/phone/rent`
export const BUY_EMAIL_URL = `${RENT_MAIL_API_URL}/email/rent`
export const BUY_FACEBOOK_URL = `${RENT_MAIL_API_URL}/facebook/rent`
export const BUY_TEXTNOW_URL = `${RENT_MAIL_API_URL}/textnow/rent`
export const GET_EMAILS_URL = `${RENT_MAIL_API_URL}/phone/search`
export const GET_BUY_EMAILS_URL = `${RENT_MAIL_API_URL}/email/search`
export const GET_BUY_FACEBOOKS_URL = `${RENT_MAIL_API_URL}/facebook/search`
export const GET_BUY_TEXTNOWS_URL = `${RENT_MAIL_API_URL}/textnow/search`
export const GET_TRANS_URL = `${RENT_MAIL_API_URL}/transaction/search`
export const GET_MAILS_REALTIME_URL = `${RENT_MAIL_API_URL}/phone/realtime`
export const GET_ADMIN_USER_INFO_URL = `${RENT_MAIL_API_URL}/admin/users`
export const GET_ADMIN_PHONE_INFO_URL = `${RENT_MAIL_API_URL}/admin/phone-users`
export const GET_ADMIN_MAIL_INFO_URL = `${RENT_MAIL_API_URL}/admin/mail-users`
export const GET_ADMIN_FB_INFO_URL = `${RENT_MAIL_API_URL}/admin/fb-users`
export const GET_ADMIN_TX_INFO_URL = `${RENT_MAIL_API_URL}/admin/tx-users`
export const GET_ADMIN_STATISTICAL_URL = `${RENT_MAIL_API_URL}/admin/statistical`
export const UPDATE_USER_INFO_URL = `${RENT_MAIL_API_URL}/user/update`
export const GET_CONFIG_URL = `${RENT_MAIL_API_URL}/admin/config`
export const UPDATE_CONFIG_URL = `${RENT_MAIL_API_URL}/admin/config`
export const RESET_ALL_THREAD = `${RENT_MAIL_API_URL}/admin/reset-thread`
export const RELEASE_WAIT_TEXTNOW = `${RENT_MAIL_API_URL}/admin/runJob`
export const UPDATE_SERVICE_URL = `${RENT_MAIL_API_URL}/admin/service`
export const IMPORT_FACEBOOK_ACCOUNT_URL = `${RENT_MAIL_API_URL}/admin/import-facebook-account`
export const IMPORT_MAIL_URL = `${RENT_MAIL_API_URL}/admin/import-mail`
export const REQUEST_PAYMENT_CODE = `${RENT_MAIL_API_URL}/payment/code`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const INSERT_API_LINK_WEBSITE = `${RENT_MAIL_API_URL}/admin/config/link-api`
export const GET_SERVICES_QUANTITY_URL = `${RENT_MAIL_API_URL}/services/quantity`

export type SearchMailParamsType = {
  status?: string[],
  serviceId?: string,
  emailUser?: string,
  rentDate?: string,
  isInit?: boolean,
  page?: number,
  limit?: number
  fromDate?: string,
  toDate?: string,
  transaction?: string,
  transactionType?: string,
}

export type SearchMailsRealtimeParamsType = {
  mailIds?: string[]
}

export type SearchTransParamsType = {
  transactionType: string | string[],
  dispEndTrans?: boolean,
  type?: string,
  serviceId?: string,
  emailUser?: string,
  page: number,
  limit: number
  fromDate?: string,
  toDate?: string
}

export type DataChartPhoneType = {
  _id: string,
  statusGroup: {year: number, month: number, day?: number, week?: number, total?: number}[],
}

export type DataChartType = {
  _id: {year: number, month: number, day?: number, week?: number},
  total: number
}

export type DataDispChartType = {
  label: {name: string, color: string},
  data: DataChartType[]
}

export type SearchPageAdminInfoParamsType = {
  email?: string,
  transType?: string,
  page: number,
  limit: number
  fromDate?: string,
  toDate?: string,
  sortCoin?: string,
  sortRecharge?: string,
  sortTrans?: string,
  sortDone?: string,
  sortExpired?: string,
  sortError?: string,
  sortWait?: string,
  sortCodeFive?: string,
  sortCodeSix?: string,
  sortScan?: string,
  sortGmailOneHour?: string,
  sortGmail24Hour?: string,
  sortGmailLive?: string,
  sortHotmail72Hour?: string,
  sortHotmailLive?: string,
  sortFbType1?: string,
  sortFbType2?: string,
  sortFbType3?: string,
  sortFbType4?: string,
  sortFbType5?: string,
  sortFbType6?: string,
  sortFbType7?: string,
  sortTxType?: string,
  serviceId?: string,
  refUserIds?: string[],
}

export type DataChartUserType = {
  rechargeChart: DataChartType[],
  doneMailChart: DataChartType[],
  expiredMailChart: DataChartType[]
}

export type AdminUserPhoneType = {
  key?: string,
  email?: string,
  numWait?: number,
  maxNumWait?: number,
  otpLength?: number,
  cancelPhonePermission?: boolean,
  textnowFromInternal?: boolean,
  userPhones?: {
    _id?: string,
    statusGroup?: {
      done?: number,
      expired?: number,
      error?: number,
      wait?: number,
    }
  }[],
  total: number,
}

export type AdminUserMailType = {
  userMails: {
    _id: string,
    email: string,
    mailGroup: {
      gmail?: number,
      gmail_live?: number,
      hotmail_72h?: number,
      hotmail_live?: number,
    }
  }[],
  total: number,
}

export type AdminUserFbType = {
  userFbs: {
    _id: string,
    email: string,
    fbGroup: {
      fb_clone_us_very_phone_anti_282_trusted_live_6_12: 0,
      fb_clone_us_ip_us_very_phone_100_us_very_phone_anti_282: 0,
      fb_clone_us_ip_us_100_usd_very_phone_anti_282: 0,
      fb_clone_us_very_phone_anti_282: 0,
      fb_clone_vn_very_phone_anti_282_trusted_live_6_12: 0,
      fb_clone_us_very_phone_anti_282_passed_ads_passed_reg_bm: 0,
      fb_clone_us_ip_us_80_usd_very_phone_anti_282: 0,
    }
  }[],
  total: number,
}

export type AdminUserTxType = {
  userFbs: {
    _id: string,
    email: string,
    txGroup: {
      textnow: 0,
    }
  }[],
  total: number,
}

export type AdminStatisticalType = {
  phone?: {
    statisticalByStatusOfService: {
      _id: string,
      statusGroup: {
        done: number,
        expired: number,
        error: number,
        wait: number,
      }[]
    },
    statisticalSumByStatus: {
      done: number,
      expired: number,
      error: number,
      wait: number,
    }
  },
  phoneInternal?: {
    statisticalByStatusOfService: {
      _id: string,
      statusGroup: {
        done: number,
        expired: number,
        error: number,
        wait: number,
      }[]
    },
    statisticalSumByStatus: {
      done: number,
      expired: number,
      error: number,
      wait: number,
    }
  },
  mail?: {
    mailStatisticalByService: {
      _id: string,
      total: number,
    }[]
  },
  facebook?: {
    fbStatisticalByService: {
      _id: string,
      total: number,
    }[]
  },
  payment?: {
    transStatistical: {
      _id: string,
      total: number,
      totalPhone?: number,
      totalPhoneInternal?: number,
      totalMail?: number,
    }[],
    transForSpecificStatistical: {
      _id: string,
      transGroup: {
        recharge: number,
        charge: number,
        refund: number,
      }
    }
  }
}

export type AdminConfigType = {
  notify?: string,
  maxTotalThread?: number,
  disabledTime?: string,
  openedTime?: string,
  bypassFb?: {
    bypassByOtp?: string,
    bypassByCancelPermission?: boolean,
    bypassByUsers?: string[],
    isBypassLimitThread?: boolean,
    bypassLimitThread?: number,
  },
  textnowResource?: string,
  proxyTextnowInternal?: {
    protocol: string,
    host: string,
    port: string,
    auth?: {
      username?: string,
      password?: string
    }
  }
}

export type ApiLinkWebsiteConfigType = {
  _id?: string,
  serviceKey: string,
  name: string,
  domain: string,
  api: string,
  responseMap: string,
  responseType: string,
  formatAccount: string,
  disabled?: boolean,
  price: number
}

export type ImportAccountType = {
  completed: number,
  error: number,
}

export type ServicesQuantity = {
  facebookOtp6: number,
  facebookOtp5: number,
  winFacebookOtp6: number,
  winFacebookOtp5: number
}

export function getServices(serviceType: string | string[]) {
  return axios.get<{code: number, services: ServiceModel[], error: string}>(GET_SERVICES_URL, { params: { type: serviceType } });
}

export function getServicesRealtime(keys: string[]) {
  return axios.get<{code: number, services: ServiceModel[], error: string}>(GET_SERVICES_REALTIME_URL, { params: { keys } });
}

export function createRentMail(serviceId: string) {
  return axios.get<{code: number, email: MailModel[], error: string}>(CREATE_EMAIL_URL, {
  params: {
    serviceId
  }});
}

export function createReRentMail(phoneId: string, serviceId: string, phone: string) {
  return axios.get<{code: number, email: MailModel[], error: string}>(CREATE_EMAIL_URL, {
  params: {
    phoneId,
    serviceId,
    phone,
  }});
}

export function createSellMail(serviceType: string, serviceId: string, numEmail: number, requestMailType: string, transactionId = '', total = 0) {
  if (serviceType === 'mail') {
    return axios.get<{code: number, emails: MailModel[], error: string}>(BUY_EMAIL_URL, {
      params: { serviceId, numEmail, transactionId, total, type: requestMailType }
    });
  } else if (serviceType === 'facebook') {
    return axios.get<{code: number, accounts: AccountFacebookModel[], error: string}>(BUY_FACEBOOK_URL, {
      params: { serviceId, numAccount: numEmail, transactionId, total }
    });
  } else if (serviceType === 'textnow') {
    return axios.get<{code: number, accounts: AccountTextnowModel[], error: string}>(BUY_TEXTNOW_URL, {
      params: { serviceId, numAccount: numEmail, transactionId, total }
    });
  }
}

export function getMails(searchParam: SearchMailParamsType) {
  return axios.get<{code: number, result: {emails: MailModel[], dataChart: DataChartType[], total: number, error: string}}>(GET_EMAILS_URL, {params: searchParam});
}

export function getBuyMails(searchParam: SearchMailParamsType) {
  if (_.get(searchParam, 'transactionType') === 'mail') {
    return axios.get<{code: number, result: {emails: MailModel[], dataChart: DataChartType[], total: number, error: string}}>(GET_BUY_EMAILS_URL, {params: searchParam});
  } else if (_.get(searchParam, 'transactionType') === 'facebook') {
    return axios.get<{code: number, result: {emails: AccountFacebookModel[], dataChart: DataChartType[], total: number, error: string}}>(GET_BUY_FACEBOOKS_URL, {params: searchParam});
  } else if (_.get(searchParam, 'transactionType') === 'textnow') {
    return axios.get<{code: number, result: {emails: AccountTextnowModel[], dataChart: DataChartType[], total: number, error: string}}>(GET_BUY_TEXTNOWS_URL, {params: searchParam});
  }
}

export function getTransaction(searchParam: SearchTransParamsType) {
  return axios.get<{code: number, result: {
    trans: TransactionModel[], total: number,
    dataChart: DataChartType[]}, error: string}>(GET_TRANS_URL, {params: searchParam});
}

export function getMailsRealtime(searchMailsRealtimeParams: SearchMailsRealtimeParamsType) {
  return axios.get<{code: number, result: {
    mailsRealtime: MailModel[]}, error: string}>(GET_MAILS_REALTIME_URL, {params: searchMailsRealtimeParams});
}

export function getAdminUserInfo(searchParam: SearchPageAdminInfoParamsType) {
  return axios.get<{code: number, result: {users: UserRentMailModel[], dataChart: DataChartUserType, total: number, error: string}}>(GET_ADMIN_USER_INFO_URL, {params: searchParam});
}

export function getAdminPhoneInfo(searchParam: SearchPageAdminInfoParamsType) {
  return axios.get<{code: number, result: AdminUserPhoneType}>(GET_ADMIN_PHONE_INFO_URL, {params: searchParam});
}

export function getAdminMailInfo(searchParam: SearchPageAdminInfoParamsType) {
  return axios.get<{code: number, result: AdminUserMailType}>(GET_ADMIN_MAIL_INFO_URL, {params: searchParam});
}

export function getAdminFbInfo(searchParam: SearchPageAdminInfoParamsType) {
  return axios.get<{code: number, result: AdminUserFbType}>(GET_ADMIN_FB_INFO_URL, {params: searchParam});
}

export function getAdminTxInfo(searchParam: SearchPageAdminInfoParamsType) {
  return axios.get<{code: number, result: AdminUserTxType}>(GET_ADMIN_TX_INFO_URL, {params: searchParam});
}

export function getAdminStatistical(searchParam: SearchPageAdminInfoParamsType) {
  return axios.get<{code: number, result: AdminStatisticalType}>(GET_ADMIN_STATISTICAL_URL, {params: searchParam});
}

export function downloadAdminStatistical(searchParam: SearchPageAdminInfoParamsType) {
  return axios.get<{code: number, result: AdminStatisticalType}>(GET_ADMIN_STATISTICAL_URL,
    {
      params: searchParam,
      responseType: 'blob',
    });
}

export function updateUserInfo(userUpdateInfo: {coin?: number, password?: string}) {
  return axios.post<{code: number, user: UserRentMailModel}>(UPDATE_USER_INFO_URL, userUpdateInfo);
}

export function getConfig() {
  return axios.get<{code: number, config: AdminConfigType, error: string}>(GET_CONFIG_URL);
}

export function updateConfig(configUpdateInfo: AdminConfigType) {
  return axios.post<{code: number, message: string, error: string}>(UPDATE_CONFIG_URL, configUpdateInfo);
}

export function resetAllThread() {
  return axios.get<{code: number, error: string}>(RESET_ALL_THREAD);
}

export function releaseWaitTextNow() {
  return axios.get<{code: number, error: string}>(RELEASE_WAIT_TEXTNOW);
}

export function updateService(params: { service: string, disabled?: boolean, isLinkApi?: boolean }) {
  return axios.get<{code: number, message: string, error: string}>(UPDATE_SERVICE_URL, { params });
}

export function importFacebookAccount(accounts: AccountFacebookModel[], importType: string) {
  return axios.post<{code: number, result: ImportAccountType}>(IMPORT_FACEBOOK_ACCOUNT_URL, {accounts, importType});
}

export function importMail(mails: MailModel[], importType: string) {
  return axios.post<{code: number, result: ImportAccountType}>(IMPORT_MAIL_URL, {mails, importType});
}

export function requestPaymentCode() {
  return axios.get<{code: number, paymentCode: string}>(REQUEST_PAYMENT_CODE);
}

export function insertApiLinkWebsite(apiLinkWebsiteInfo: ApiLinkWebsiteConfigType) {
  return axios.post<{code: number, message: string, error: string}>(INSERT_API_LINK_WEBSITE, apiLinkWebsiteInfo);
}

export function getApiLinkWebsiteConfig() {
  return axios.get<{code: number, result: {apiLinkWebsites: ApiLinkWebsiteConfigType[]}, error: string}>(INSERT_API_LINK_WEBSITE);
}

export function updateApiLinkWebsite(apiLinkWebsiteInfo: any) {
  return axios.put<{code: number, message: string, error: string}>(INSERT_API_LINK_WEBSITE, apiLinkWebsiteInfo);
}

export function deleteApiLinkWebsite(apiLinkWebsiteId: string) {
  return axios.delete<{code: number, message: string, error: string}>(INSERT_API_LINK_WEBSITE, { params: { _id: apiLinkWebsiteId } });
}

export function getServicesQuantity() {
  return axios.get<{code: number, services: ServicesQuantity, error: string}>(GET_SERVICES_QUANTITY_URL);
}
