/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect } from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../auth/redux/AuthRedux'
import {register} from '../auth/redux/AuthCRUD'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { PasswordMeterComponent } from "../../../_metronic/assets/ts/components";
import {
  createUserWithEmailAndPassword
} from "./firebase";
import {
  signInWithGoogle
} from "./firebase";

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: ''
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, 'Tên phải có ít nhất 2 kí tự')
    .max(50, 'Tên chỉ có nhiều nhất 50 kí tự')
    .required('Vui lòng nhập tên'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Email phải có ít nhất 3 kí tự')
    .max(50, 'Email chỉ có nhiều nhất 50 kí tự')
    .required('Vui lòng nhập Email'),
  lastname: Yup.string()
    .min(2, 'Họ phải có ít nhất 2 kí tự')
    .max(50, 'Họ chỉ có nhiều nhất 50 kí tự')
    .required('Vui lòng nhập họ'),
  password: Yup.string()
    .min(3, 'Mật khẩu phải có ít nhất 3 kí tự')
    .max(50, 'Mật khẩu chỉ có nhiều nhất 50 kí tự')
    .required('Vui lòng nhập mật khẩu'),
  changepassword: Yup.string()
    .required('Vui lòng nhập mật khẩu xác nhận')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Mật khẩu và Mật khẩu xác nhận không khớp"),
    }),
})

export function RentMailRegistration() {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      createUserWithEmailAndPassword(values.firstname, values.lastname, values.email, values.password,
        (user: {uid: string, name: string, email: string}, idToken: string) => {
        setLoading(false);
        dispatch(auth.actions.registerByEmailPassword(user, idToken));
      }, (errorMsg: string) => {
        setLoading(false);
        setErrorMsg(errorMsg);
      });
    },
  })

  useEffect(()=>{
    PasswordMeterComponent.bootstrap();
  }, []);

  const signInByGoogle = () => {
    signInWithGoogle((accessToken: string, idToken: string) => {
        dispatch(auth.actions.loginByGoogle(accessToken, idToken));
    });
  };

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Tạo tài khoản</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Đã có tài khoản?
          <Link to='/auth' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Đăng nhập
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {errorMsg ? <div className='mb-10 bg-light-warning p-8 rounded'>
          <div className='text-danger'>
            <strong>{errorMsg}</strong>
          </div>
        </div> : null}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>Tên</label>
          <input
            placeholder='Tên'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='fw-bolder text-dark fs-6'>Họ</label>
            <input
              placeholder='Họ'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.lastname && formik.errors.lastname,
                },
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastname}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Mật khẩu</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Mật khẩu'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
              className="d-flex align-items-center mb-3"
              data-kt-password-meter-control="highlight"
          >
            <div
                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
            ></div>
            <div
                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
            ></div>
            <div
                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
            ></div>
            <div
                className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
            ></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          Dùng nhiều hơn 8 kí tự bao gồm chữ, số và kí tự đặc biệt.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Xác nhận Mật khẩu</label>
        <input
          type='password'
          placeholder='Xác nhận Mật khẩu'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={loading || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Đăng kí</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              vui lòng đợi...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Huỷ
          </button>
        </Link>
      </div>
      {/* end::Form group */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>HOẶC</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {/* begin::Action */}
      <button onClick={signInByGoogle} type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Đăng nhập bằng Google'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Đăng nhập bằng Google
      </button>
      {/* end::Action */}
    </form>
  )
}
