/* eslint-disable jsx-a11y/anchor-is-valid */
import { Divider } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as rentMail from '../redux/RentMailRedux'
import RentMailPagination from './RentMailPagination'
import MomentLocaleUtils from 'react-day-picker/moment';
import datePickerMoment from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import 'moment/locale/vi';
import { Button } from 'react-bootstrap';
import { TransactionModel } from '../models/TransactionModel';
import _ from 'lodash'
import { RootState } from '../../../../setup'
import { useFormik } from 'formik'
import { ServiceModel } from '../models/ServiceModel'
import { useSearchParams } from 'react-router-dom'
import { toIsoString } from '../common/common'

type Props = {
  transactions: TransactionModel[],
  services: ServiceModel[],
  totalRows: number,
  className: string
}

const FacebookTransactionTable: React.FC<Props> = ({ services, transactions, totalRows, className }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdmin = useSelector<RootState>(({auth}) => _.get(auth, 'userRentMail.isAdmin'), shallowEqual) as boolean;

  const [service, setService] = useState('');
  const [emailUser, setEmailUser] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState(moment().add(-30, 'days').toDate());
  const [toDate, setToDate] = useState(moment().toDate());
  const dispatch = useDispatch();

  useEffect(() => {
    const userEmail = searchParams.get('userEmail') as string;
    if (userEmail) {
      formik.setFieldValue('email', userEmail);
      setEmailUser(userEmail);
    }
  }, [searchParams]);

  const formik = useFormik({
    initialValues: {
      service: '',
      email: '',
    },
    onSubmit: () => {
    },
  });

  const callbackPageChange = (page: number) => {
    dispatch(rentMail.actionsRentMails.getTrans({
      transactionType: 'facebook',
      fromDate: toIsoString(new Date(datePickerMoment.formatDate(fromDate, 'YYYY/MM/DD'))),
      toDate: toIsoString(new Date(datePickerMoment.formatDate(toDate, 'YYYY/MM/DD'))),
      emailUser,
      serviceId: service,
      page,
      limit: 10}));
    setCurrentPage(page);
  }

  const onClickSearch = () => {
    const service = formik.values.service;
    const emailUser = formik.values.email;
    setService(service);
    setEmailUser(emailUser);
    dispatch(rentMail.actionsRentMails.getTrans({
      transactionType: 'facebook',
      fromDate: toIsoString(new Date(datePickerMoment.formatDate(fromDate, 'YYYY/MM/DD'))),
      toDate: toIsoString(new Date(datePickerMoment.formatDate(toDate, 'YYYY/MM/DD'))),
      emailUser,
      serviceId: service,
      page: 1,
      limit: 10}));
    setCurrentPage(1);
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3'>Tìm kiếm</span>
        </h3>
      </div>
      
      <div className="card-body py-3">
        <form>
          <div className='row mb-6'>
            <label className='col-lg-1 col-form-label fw-bold fs-6'>
              <span>Dịch vụ:</span>
            </label>
            <div className='col-lg-3 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('service')}
              >
                <option value=''>Chọn dịch vụ...</option>
                {services.map((s, idx) => <option key={idx} value={s.key}>{s.name}</option>)}
              </select>
            </div>

            {isAdmin ? <>
              <label className='col-lg-1 col-form-label fw-bold fs-6'>
                <span>Email user: </span>
              </label>
              <div className='col-lg-3 fv-row'>
                <input
                  placeholder='Email...'
                  {...formik.getFieldProps('email')}
                  className='form-control form-control-lg form-control-solid'
                  type='email'
                  name='email'
                  autoComplete='off'
                />
              </div>
            </> : null }
          </div>
          <div className='row mb-6'>
            <label className='col-lg-1 col-form-label fw-bold fs-6'>
              <span>Từ ngày:</span>
            </label>
            <div className='col-lg-2 fv-row'>
              <DayPickerInput
                value={datePickerMoment.formatDate(fromDate, 'LL', 'vi')}
                formatDate={datePickerMoment.formatDate}
                parseDate={datePickerMoment.parseDate}
                format="LL"
                placeholder={`${datePickerMoment.formatDate(fromDate, 'LL', 'vi')}`}
                dayPickerProps={{
                  locale: 'vi',
                  localeUtils: MomentLocaleUtils,
                  disabledDays: {after: new Date()}
                }}
                onDayChange={(day) => setFromDate(moment(day).toDate())}
              />
            </div>
            <label className='col-lg-1 col-form-label fw-bold fs-6'>
              <span>Đến ngày:</span>
            </label>
            <div className='col-lg-2 fv-row'>
              <DayPickerInput
                value={datePickerMoment.formatDate(toDate, 'LL', 'vi')}
                formatDate={datePickerMoment.formatDate}
                parseDate={datePickerMoment.parseDate}
                format="LL"
                placeholder={`${datePickerMoment.formatDate(toDate, 'LL', 'vi')}`}
                dayPickerProps={{
                  locale: 'vi',
                  localeUtils: MomentLocaleUtils,
                  disabledDays: {after: new Date()}
                }}
                onDayChange={(day) => setToDate(moment(day).toDate())}
              />
            </div>
            <div className='col-lg-2 fv-row'>
              <Button className='btn btn-primary' onClick={onClickSearch}>Tìm kiếm</Button>
            </div>
          </div>
        </form>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-100px'>Thời gian</th>
                <th className='w-500px'>Loại</th>
                <th className='min-w-50px'>Số lượng</th>
                <th className='min-w-100px'>Đơn giá</th>
                <th className='min-w-100px'>Tổng tiền (VNĐ)</th>
                <th className='min-w-200px'>Biến động</th>
                <th className='min-w-200px'>Note</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
                transactions.map((trans, idx) => {
                  let colorChipStatus = 'background-color-used';
                  let transType = 'Đã thanh toán';
                  switch (trans.type) {
                    case 'refund':
                      colorChipStatus = 'background-color-expired';
                      transType = 'Hoàn tiền';
                      break;
                    case 'recharge':
                      colorChipStatus = 'background-color-recharge';
                      transType = 'Nạp tiền';
                      break;
                  }
                  return <tr key={idx} style={{ lineHeight: '8px' }} className={idx%2===0 ? 'background-color-table-row' : ''}>
                    <td>
                      <span className='d-block fw-bolder'>
                        {moment(trans.createdTime).format('DD-MM-YYYY HH:mm:ss')}
                      </span>
                    </td>
                    {/* <td>
                      <Chip className={`fs-6 ${colorChipStatus} text-white`} label={transType} />
                    </td> */}
                    <td>
                      <span className='d-block fw-bolder' style={{lineHeight: 'normal'}}>
                        {trans.serviceName}
                      </span>
                    </td>
                    <td>
                      <span className='d-block fw-bolder'>
                        {trans.total?.toLocaleString("en-US")}
                      </span>
                    </td>
                    <td>
                      <span className='d-block fw-bolder'>
                        {trans.price?.toLocaleString("en-US")}
                      </span>
                    </td>
                    <td>
                      <span className='d-block fw-bolder'>
                        {(trans.price * trans.total).toLocaleString("en-US")}
                      </span>
                    </td>
                    <td>
                      <span className='d-block fw-bolder'>
                        {trans.balanceBefore?.toLocaleString("en-US")} {`---->`} {trans.balanceAfter?.toLocaleString("en-US")}
                      </span>
                    </td>
                    <td>
                      <span className='d-block fw-bolder'>
                        {_.get(trans, 'note', '').replace('charge ', 'Đã mua ').replace('refund ', 'Hoàn tiền ').replace('recharge ', 'Nạp tiền ')}
                      </span>
                    </td>
                  </tr>
                })
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {transactions.length !== 0 ? <RentMailPagination
            numRows={totalRows}
            currentPage={currentPage}
            numRowsPerPage={10}
            callbackPageChange={callbackPageChange}
          /> : null}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export {FacebookTransactionTable}
