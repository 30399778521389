/* eslint-disable jsx-a11y/anchor-is-valid */
import { Divider } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Modal, OverlayTrigger, Toast, Tooltip } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { MailModel } from '../models/MailModel';
import * as rentMail from '../redux/RentMailRedux';
import RentMailPagination from './RentMailPagination';
import _ from 'lodash';
import { TransactionModel } from '../models/TransactionModel';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import clsx from 'clsx';
import { ServiceModel } from '../models/ServiceModel';
import { toIsoString } from '../common/common';
import { Link } from 'react-scroll';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { AccountFacebookModel } from '../models/AccountFacebookModel';

type Props = {
  services: ServiceModel[],
  transactions: TransactionModel[],
  totalRows: number,
  onHandleBuyEmail: Function,
  warningMsg: string,
  refreshWarningMsg: Function,
  className: string
}

const initialValues = {
  numEmail: '',
}

let numEmailSchemaInit = Yup.object().shape({
  numEmail: Yup.number()
    .moreThan(0, 'Vui lòng nhập số lượng lớn hơn 0')
    .lessThan(5001, 'Chỉ có thể mua nhiều nhất 5000 mail')
    .required('Vui lòng nhập số lượng mail cần mua'),
});

const SellMailTable: React.FC<Props> = ({services = [], transactions = [], totalRows,
  onHandleBuyEmail, warningMsg, refreshWarningMsg, className}) => {
  const rentMailCreatedSuccess: boolean =
    // @ts-ignore
    useSelector<RootState>(({rentMail}) => rentMail.rentMailCreatedSuccess, shallowEqual) as boolean;
  const transactionCreatedSuccess: boolean =
    // @ts-ignore
    useSelector<RootState>(({rentMail}) => rentMail.transactionCreatedSuccess, shallowEqual) as boolean;
  const rentMailsCreated: {emails: MailModel[], accounts: AccountFacebookModel[], total: number} =
    useSelector<RootState>(({rentMail}) => rentMail.rentMailsCreated, shallowEqual) as {emails: MailModel[], accounts: AccountFacebookModel[], total: number};
  const isCopyingBuyEmails: boolean =
    // @ts-ignore
    useSelector<RootState>(({rentMail}) => rentMail.isCopyingBuyEmails, shallowEqual) as boolean;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageMail, setCurrentPageMail] = useState(1);
  const [currentTransactionView, setCurrentTransactionView] = useState('');
  const [copyMail, setCopyMail] = useState('');
  const [copyMailRecovery, setCopyMailRecovery] = useState('');
  const [copyPassword, setCopyPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [numEmailSchema, setNumEmailSchema] = useState(numEmailSchemaInit);
  const [serviceBuy, setServiceBuy] = useState('');
  const [mailTypeBuy, setMailTypeBuy] = useState<string|undefined>('');
  const dispatch = useDispatch();

 // 1+_.get(_.find(services, serv => serv.key === formik.values.service)
  const formik = useFormik({
    initialValues,
    validationSchema: numEmailSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      dispatch(rentMail.actionsRentMails.refreshTransactionCreatedSuccess());
      onHandleBuyEmail(serviceBuy, values.numEmail, mailTypeBuy);
    },
  });

  useEffect(() => {
    const numEmail = !mailTypeBuy ? _.get(_.find(services, serv => serv.key === serviceBuy), 'total', 5000) : _.get(_.find(services, serv => serv.key === 'gmail' && serv.typeRequest === mailTypeBuy), 'total', 5000);
    const numEmailSchemaUpdated = Yup.object().shape({
      numEmail: Yup.number()
        .moreThan(0, 'Vui lòng nhập số lượng lớn hơn 0')
        .lessThan(1+numEmail,
          `Chỉ có thể mua nhiều nhất ${numEmail} mail`)
        .required('Vui lòng nhập số lượng mail cần mua'),
    });
    setNumEmailSchema(numEmailSchemaUpdated);
  }, [serviceBuy]);

  useEffect(() => {
    if (transactionCreatedSuccess) {
      setServiceBuy('');
      formik.resetForm();
    }
  }, [transactionCreatedSuccess]);

  const callbackPageChange = (page: number) => {
    dispatch(rentMail.actionsRentMails.getTrans({
      transactionType: ['mail', 'facebook'],
      fromDate: toIsoString(new Date(moment().format('YYYY/MM/DD'))),
      toDate: toIsoString(new Date(moment().format('YYYY/MM/DD'))),
      page,
      limit: 10}));
    setCurrentPage(page);
  };
  const callbackPageMailChange = (page: number) => {
    dispatch(rentMail.actionsRentMails.getBuyMails({
      transactionType: 'mail',
      transaction: currentTransactionView,
      page,
      limit: 20
    }));
    setCurrentPageMail(page);
  };
  const setCopiedMail = (mailId: string) => {
    setCopyMail(mailId);
  };
  const setCopiedMailRecovery = (mailId: string) => {
    setCopyMailRecovery(mailId);
  };
  const setCopiedPassword = (mailId: string) => {
    setCopyPassword(mailId);
  };
  const getEmailsForTransaction = (transactionType: string, transaction: string) => {
    dispatch(rentMail.actionsRentMails.getBuyMails({
      transactionType,
      transaction,
      page: 1,
      limit: 20}));
    setCurrentTransactionView(transaction);
    if (transactionType === 'mail') {
      setShowModal(true);
    } else if (transactionType === 'facebook') {
      // TODO for show facebook info
    }
  }

  const getMailIcon = (key: string, size = 32) => {
    if (key.includes('gmail')) {
      return <img style={{height: `${size}px`, marginBottom: '5px', marginRight: '3px'}} src={toAbsoluteUrl(`/media/icons/duotune/social/google.svg`)}/>
    } else if (key.includes('hotmail')) {
      return <img style={{height: `${size}px`, marginBottom: '5px', marginRight: '3px'}} src={toAbsoluteUrl(`/media/icons/duotune/social/hotmail.png`)}/>
    } else if (key.includes('textnow')) {
      return <img style={{height: `${size}px`, marginBottom: '5px', marginRight: '3px'}} src={toAbsoluteUrl(`/media/icons/duotune/social/textnow.png`)}/>
    } else if (key.includes('facebook')) {
      return <img style={{height: `${size}px`, marginBottom: '5px', marginRight: '3px'}} src={toAbsoluteUrl(`/media/icons/duotune/social/facebook.svg`)}/>
    }
  }

  const renderMailType = (key: string) => {
    const servicesRendered: ServiceModel[] = key !== 'hotmail' ? _.filter(services, s => s.key.includes(key)) : [];
    return (
      <div className='col-xxl-12 border-mail'>
        <div className={`card card-xxl-stretch`}>
          <div className="card-body py-3 fw-bold">
            <div className='table-responsive'>
              <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th style={{width: '5%'}}>Số TT</th>
                    <th style={{width: '70%'}}>Loại</th>
                    <th style={{width: '10%'}}>Số lượng</th>
                    <th style={{width: '10%'}}>Đơn giá</th>
                    <th style={{width: '5%'}}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    servicesRendered.map((serv, idx) => {
                      if (!serv) {
                        return null;
                      }
                      return <tr key={idx} style={{ lineHeight: '8px' }} className={idx%2===0 ? 'background-color-table-row' : ''}>
                        <td>
                          <span className='fw-bolder'>{idx+1}</span>
                        </td>
                        <td>
                          {getMailIcon(serv.type === 'facebook' ? serv.type : serv.key)}
                          <span className='fs-6 fw-bolder' style={{lineHeight: 'normal'}}>{serv.name}</span>
                          {serv.note ? <>
                            <br/>
                            <br/>
                            <span style={{color: 'red', lineHeight: 'normal'}}>{serv.note ? serv.note : ''}</span>
                          </> : null}
                          {/* <DarkTooltip title={serv.note ? serv.note : ''} placement="right-start">
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                            ></i>
                          </DarkTooltip> */}
                        </td>
                        <td>
                          <span className='fs-6 fw-bolder'>{_.get(serv, 'total', 0).toLocaleString("en-US")}</span>
                        </td>
                        <td>
                          <span className='fs-6 fw-bolder'>{_.get(serv, 'price', 0).toLocaleString("en-US")}</span>
                        </td>
                        <td>
                          <button
                            type="submit"
                            className='btn btn-success'
                            onClick={() => {
                              setServiceBuy(serv.key);
                              setMailTypeBuy(serv?.typeRequest)
                            }}
                            disabled={serv.total === 0}
                          >
                            Mua
                          </button>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className={`card ${className} pt-6 pb-6`}>
      { transactionCreatedSuccess ? <div className='card-body py-1'>
        <span className='fs-4' style={{ color: 'limegreen', fontWeight: 'bold' }}>Giao dịch thành công</span><br/>
        <span className='fs-6'>Loại: </span><span className='fw-bolder fs-6'>{_.get(transactions, '[0].serviceName')}</span><br/>
        <span className='fs-6'>Số lượng: </span><span className='fw-bolder fs-6'>{_.get(transactions, '[0].total')}</span><br/>
        <span className='fs-6'>Tổng số tiền: </span><span className='fw-bolder fs-6'>{_.get(transactions, '[0].total', 0)*_.get(transactions, '[0].price', 0)} VNĐ</span><br/>
        <span className='fs-6'>Vào lúc: </span><span className='fs-6' style={{ fontStyle: "italic" }}>{moment(_.get(transactions, '[0].createdTime')).format('DD-MM-YYYY HH:mm:ss')}</span><br/><br/>
        {/* @ts-ignore */}
        {_.get(transactions, '[0].transactionType') === 'mail' ? <><a className='highlight-copy card-hover-pointer' onClick={() => getEmailsForTransaction(_.get(transactions, '[0].transactionType') as string, _.get(transactions, '[0]._id'))}>View </a>| </> : null}
        {/* @ts-ignore */}
          <a className='highlight-copy card-hover-pointer' onClick={() => dispatch(rentMail.actionsRentMails.DownloadEmailsTransaction(_.get(transactions, '[0].transactionType'), _.get(transactions, '[0]._id')))}>Download</a>
      </div> : null }
      { transactionCreatedSuccess ? <Divider variant="inset" /> : null }
      {/* <div className="card-body py-1 fs-8 mt-6">
        <span className='fw-bolder'>SẢN PHẨM:</span>
      </div> */}
      <div className='row card-body py-1 pt-6 mb-6'>
        <div className='col-lg-2 fv-row' style={{display: 'flex', alignItems: 'center'}}>
          <span className='fw-bolder fs-4' style={{color: '#1890ff'}}>SẢN PHẨM:</span>
        </div>
        <div className='col-lg-1 fv-row' style={{textAlign: 'center'}}>
          <Link activeClass="active" to="gmail" spy={true} smooth={true}>
            <img style={{height: '48px'}} src={toAbsoluteUrl(`/media/icons/duotune/social/google.svg`)}/>
          </Link>
          <br/>
          <span>Gmail</span>
        </div>
        <div className='col-lg-1 fv-row' style={{textAlign: 'center'}}>
          <Link activeClass="active" to="hotmail" spy={true} smooth={true}>
            <img style={{height: '48px'}} src={toAbsoluteUrl(`/media/icons/duotune/social/hotmail.png`)}/>
          </Link>
          <br/>
          <span>Hotmail</span>
        </div>
        <div className='col-lg-1 fv-row' style={{textAlign: 'center'}}>
          <Link activeClass="active" to="textnow" spy={true} smooth={true}>
            <img style={{height: '48px'}} src={toAbsoluteUrl(`/media/icons/duotune/social/textnow.png`)}/>
          </Link>
          <br/>
          <span>Textnow</span>
        </div>
        <div className='col-lg-1 fv-row' style={{textAlign: 'center'}}>
          <Link activeClass="active" to="facebook" spy={true} smooth={true}>
            <img style={{height: '48px'}} src={toAbsoluteUrl(`/media/icons/duotune/social/facebook.svg`)}/>
          </Link>
          <br/>
          <span>Facebook</span>
        </div>
      </div>
      <div className='card-body py-0'>
        {getMailIcon('gmail', 48)}
        <span id='gmail' className='fs-6 fw-bolder'>Gmail</span>
        {renderMailType('gmail')}
        <br/>
        {getMailIcon('hotmail', 48)}
        <span id='hotmail' className='fs-6 fw-bolder'>Hotmail</span>
        {renderMailType('hotmail')}
        <br/>
        {getMailIcon('textnow', 48)}
        <span id='textnow' className='fs-6 fw-bolder'>Textnow</span>
        {renderMailType('textnow')}
        <br/>
        {getMailIcon('facebook', 48)}
        <span id='facebook' className='fs-6 fw-bolder'>Facebook</span>
        {renderMailType('fb')}
        {/* end::Table container */}
      </div>
      {transactions.length !== 0 ? <><br/><Divider variant="inset" /></> : null}
      {transactions.length !== 0 ? <div>
        <div className="card-body py-1 fw-bolder fs-6 mt-6">
          <span style={{color: 'limegreen'}}>Các giao dịch ngày hôm nay:</span>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-0'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th style={{width: '4%'}}>Số TT</th>
                  <th style={{width: '27%'}}>Loại</th>
                  <th style={{width: '10%'}}>Số lượng</th>
                  <th style={{width: '15%'}}>Tổng tiền (VNĐ)</th>
                  <th style={{width: '20%'}}>Giao dịch lúc</th>
                  <th style={{width: '20%'}}>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {
                  transactions.map((trans, idx) => {
                    if (!trans) {
                      return null;
                    }
                    return <tr key={trans._id} style={{ lineHeight: '8px' }} className={idx%2===0 ? 'background-color-table-row' : ''}>
                      <td>
                        <span className='text-dark fw-bolder'>{idx+1}</span>
                      </td>
                      <td>
                        <span className='fs-6' style={{lineHeight: 'normal'}}>{trans.serviceName}</span>
                      </td>
                      <td>
                        <span className='fs-6'>{trans.total}</span>
                      </td>
                      <td>
                        <span className='fs-6'>{trans.total*trans.price}</span>
                      </td>
                      <td>
                        <span className='fs-6' style={{lineHeight: 'normal'}}>
                          {moment(trans.createdTime).format('DD-MM-YYYY HH:mm:ss')}
                        </span>
                      </td>
                      <td>
                        {trans.transactionType === 'mail' ? <><a className='highlight-copy card-hover-pointer fs-6' onClick={() => getEmailsForTransaction(trans.transactionType, trans._id)}>View </a>| </> : null }
                          <a className='highlight-copy card-hover-pointer fs-6' onClick={() => dispatch(rentMail.actionsRentMails.DownloadEmailsTransaction(trans.transactionType, trans._id))}>Download</a>
                      </td>
                    </tr>
                  })
                }
              </tbody>
              {/* end::Table body */}
            </table>
            {transactions.length !== 0 ? <RentMailPagination
              numRows={totalRows}
              currentPage={currentPage}
              numRowsPerPage={10}
              callbackPageChange={callbackPageChange}
            /> : null}
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div> : null}
      {/* Modal for mail view detail */}
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setCurrentPageMail(1);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div style={{ textAlign: 'end' }}>
            <Button variant="primary" size="sm" onClick={() => dispatch(rentMail.actionsRentMails.CopyBuyEmails(currentTransactionView))}>
              {!isCopyingBuyEmails && <span className='indicator-label'>Copy all</span>}
              {isCopyingBuyEmails && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Copying...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>)}
            </Button>
          </div>
          <div style={{
            height: "400px",
            overflowY: "scroll" }}>
            <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-100px'>Email</th>
                  <th className='min-w-100px'>Email Recovery</th>
                  <th className='min-w-100px'>Password</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.get(rentMailsCreated, 'lstRentMailCreated', []).slice(0, 20).map((mail: MailModel, idx: number) => {
                    if (!mail) {
                      return null;
                    }
                    return <tr key={idx} style={{ lineHeight: '8px' }} className={idx%2===0 ? 'background-color-table-row' : ''}>
                      <td>
                        <OverlayTrigger
                          key='copy-to-clipboard'
                          placement='top'
                          overlay={<Tooltip id='tooltip-copy-to-clipboard'>{copyMail === mail._id ? 'copied' : 'Click to copy'}</Tooltip>}
                        >
                          <CopyToClipboard text={mail.email} onCopy={() => setCopiedMail(mail._id)}>
                            <a className='highlight-copy card-hover-pointer'>
                              <span className='text-primary d-block'>
                                {mail.email}
                              </span>
                            </a>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </td>
                      <td>
                        <OverlayTrigger
                          key='copy-to-clipboard'
                          placement='top'
                          overlay={<Tooltip id='tooltip-copy-to-clipboard'>{copyMailRecovery === mail._id ? 'copied' : 'Click to copy'}</Tooltip>}
                        >
                          <CopyToClipboard text={mail.mailRecovery} onCopy={() => setCopiedMailRecovery(mail._id)}>
                            <a className='highlight-copy card-hover-pointer'>
                              <span className='text-primary d-block'>
                                {mail.mailRecovery}
                              </span>
                            </a>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </td>
                      <td>
                        <OverlayTrigger
                          key='copy-to-clipboard'
                          placement='top'
                          overlay={<Tooltip id='tooltip-copy-to-clipboard'>{copyPassword === mail._id ? 'copied' : 'Click to copy'}</Tooltip>}
                        >
                          <CopyToClipboard text={mail.password} onCopy={() => setCopiedPassword(mail._id)}>
                            <a className='highlight-copy card-hover-pointer'>
                              <span className='text-primary d-block'>
                                {mail.password}
                              </span>
                            </a>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
          {_.get(rentMailsCreated, 'lstRentMailCreated', []).slice(0, 20).length !== 0 ? <RentMailPagination
            numRows={_.get(rentMailsCreated, 'total', 0)}
            currentPage={currentPageMail}
            numRowsPerPage={20}
            callbackPageChange={callbackPageMailChange}
          /> : null}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer> */}
      </Modal>
      <Modal
        show={!!serviceBuy}
        onHide={() => {
          setServiceBuy('');
          formik.resetForm();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName='modal-dialog-centered mw-500px h-auto'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{color: '#1890ff'}}>{_.get(_.find(services, s => s.key === serviceBuy), 'name', '')}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card-body py-3">
            <Toast
              show={!rentMailCreatedSuccess}
              onClose={() => {
                dispatch(rentMail.actionsRentMails.refreshRentMailCreatedSuccess());
              }}
              delay={3000}
              autohide
              className="background-color-expired-toast">
              <Toast.Body>
                <span className="text-white">{warningMsg ? warningMsg : `Kho ${_.get(_.find(services, s => s.key === serviceBuy), 'type', '')} đã hết.`}</span>
              </Toast.Body>
            </Toast>

            <Toast
              show={!!warningMsg}
              onClose={() => {
                refreshWarningMsg();
              }}
              delay={4000}
              autohide
              className="background-color-expired-toast">
              <Toast.Body className="width-100">
                <span className="text-white">{warningMsg}</span>
              </Toast.Body>
            </Toast>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <label className="form-label fw-bolder">
                  {serviceBuy ?
                    `Nhập số lượng (tối đa là ${!mailTypeBuy ? _.get(_.find(services, serv => serv.key === serviceBuy), 'total', 5000) : _.get(_.find(services, serv => serv.key === 'gmail' && serv.typeRequest === mailTypeBuy), 'total', 5000)})` : 'Nhập số lượng'}
                </label>
                <input
                  type="number"
                  placeholder="0"
                  min={0}
                  max={!mailTypeBuy ? _.get(_.find(services, serv => serv.key === serviceBuy), 'total', 5000) : _.get(_.find(services, serv => serv.key === 'gmail' && serv.typeRequest === mailTypeBuy), 'total', 5000)}
                  {...formik.getFieldProps('numEmail')}
                  className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.numEmail && formik.errors.numEmail,
                      },
                      {
                        'is-valid': formik.touched.numEmail && !formik.errors.numEmail,
                      }
                  )}
                />
                {formik.touched.numEmail && formik.errors.numEmail && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.numEmail}</span>
                      </div>
                    </div>
                )}
                <div className='row mt-6'>
                  <label className='col-lg-5 col-form-label fw-bolder fs-7'>
                    <span>Tổng số tiền (VNĐ):</span>
                  </label>
                  <label className='col-lg-3 col-form-label fw-bolder fs-7'>
                    <span style={{color: 'red', fontSize: 'large'}}>
                      {(+formik.values.numEmail)*_.get(_.find(services, s => s.key === serviceBuy), 'price', 0)}
                    </span>
                  </label>
                </div>
                <div style={{marginTop: '27px'}}>
                  <button
                    type="submit"
                    className='btn btn-success'
                    disabled={!formik.isValid || formik.values.numEmail === ''}
                  >
                    Mua
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export {SellMailTable}
